<template>
  <div class="card card-profile shadow flex-grow-1">
    <div v-if="classImgFly" class="p-3 mx-4 text-center card-header">
      <div class="row justify-content-center">
        <div class="col-lg-3 order-lg-2">
          <div class="card-profile-image">
            <img :src="classImgFly" class="rounded-circle" />
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="classImg" class="text-center">
      <div class="card-body pb-0">
        <div class="text-center">
          <img :src="classImg" :style="{ width: '55%' }"/>
        </div>
      </div>  
    </div>        
    <div class="text-center card-body" :class="classImgFly ? 'pb-3 mt-7' : 'p-0 pt-0'">
      <h2 class="mb-0 text-center tag text-warning pb-3">{{ title }}</h2>
      <span class="coltext-center mt-md-3 mb-2 text-muted">{{ desc }}</span>
      <div>
        
        <slot name="button"></slot> 
        
      </div>
      <slot></slot> 
    </div>
  </div>
</template>

<script>

export default {
  name: "default-info-card",
  props: {
    icon_bg: {
      type: String,
      default: "bg-white",
    },
    classImgFly: {
      type: String,
    },
    classImg: {
      type: String,
    },
    buttonIcon: {
      type: String,
      
    },
    title: String,
    desc: String,
    price: String,
    link: String,
  },
  components: {
    
  },
};
</script>
<style>

</style>