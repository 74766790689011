import axios from "axios";
import store from "@/store/index.js";

const isProd = process.env.NODE_ENV === "production";

const apiUrl = isProd
  ? "https://portalhubinovacao.com.br/api-portalhub/"
  : "http://127.0.0.1:3333";

const api = axios.create({
  baseURL: apiUrl,
  timeout: 5000,
});

api.interceptors.request.use(async (config) => {
  const token = store.state.auth.token;
  if (token){
    if ((store.state.roleMirror || store.state.role) && store.state.prefixMirror) {
      if (config.method !== "get") {
        return false;
      }
      config.url = `${store.state.prefixMirror}${config.url}`;
    }

    config.headers.Authorization = `Bearer ${token}`;
    
  } 
  return config;
});

export default api;
