<template>
  <card-from-stats
    class="card-stats"
    :show-footer-line="true"
    :bodyClasses="{ 'pb-1': margin }"
  >
    <div class="row mb-2" v-if="iconInTop">
      <div class="col-12 text-center">
        <div
          class="icon icon-shape text-white rounded-circle shadow icone border-0"
          :class="[`bg-${type}`, `bg-${gradient}` ,iconClasses]"
          :style="{
            background: iconInTop && !margin
              ? $store.getters.colorGradient({
                  color: 'primary',
                  gradient: -20,
                })
              : type
          }"
        >
          <i class="ms-0" :class="icon"></i>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col" :class="init_page ? 'p-0' : 'ps-3 pe-0'">
        <slot>
          <h5
            :class="[
              margin ? 'mb-3' : 'mb-0',
              'card-title text-uppercase text-muted',
              iconInTop ? 'text-center' : ''
            ]"
            v-if="title"
          >
            {{ title }}
          </h5>
          <span
            class="h2 font-weight-bold mb-0"
            v-if="subTitle && init_page"
            v-html="subTitle"
          ></span>
          <span class="h2 font-weight-bold mb-0" v-else-if="subTitle">{{
            subTitle
          }}</span>
          <a
            @click="$emit('card-pop-up')"
            class="text-primary fw-bold cursor-pointer d-flex justify-content-center align-items-center"
            v-if="openPopUp && contentButtonPopUp"
            >{{ contentButtonPopUp }}
            <i class="fas fa-external-link-alt ms-1 fs-4"></i
          ></a>
          <div v-else class="px-5">
            <span v-html="contentCard"></span>
          </div>
        </slot>
      </div>

      <div
        class="col-auto"
        v-if="!iconInTop && ($slots.icon || icon)"
        :class="[cursorPointer ? 'cursor-pointer' : '']"
        @click="link && $router.push(link)"
      >
        <slot name="icon">
          <div
            class="icon icon-shape text-white rounded-lg-circle shadow icone"
            :class="[hover ? `bg-${styleHover}` : `bg-${type}`, iconClasses]"
            @mouseover="() => (hover = true)"
            @mouseout="() => (hover = false)"
          >
            <i :class="icon" v-if="!link"></i>
            <i
              v-else
              class="icon"
              :class="[icon, cursorPointer ? 'cursor-pointer' : '']"
              title="Abrir página"
            ></i>
          </div>
        </slot>
      </div>
    </div>

    <p class="mt-3 mb-0 text-sm" v-if="!margin">
      <slot name="footer"> </slot>
    </p>
  </card-from-stats>
</template>
<script>
export default {
  name: "stats-card",
  data() {
    return {
      hover: false,
      styleHover: "",
    };
  },
  components: {},
  props: {
    type: {
      type: String,
      default: "primary",
    },
    gradient: {
      type: String,
      default: "gradient-info",
    },
    Hover: {
      type: String,
    },
    icon: String,
    title: String,
    subTitle: String,
    contentButtonPopUp: String,
    contentCard: String,
    iconClasses: [String, Array],
    margin: {
      type: Boolean,
      default: false,
    },
    init_page: {
      type: Boolean,
      default: false,
    },
    openPopUp: {
      type: Boolean,
      default: false,
    },
    cursorPointer: {
      type: Boolean,
      default: true,
    },
    iconInTop: {
      type: Boolean,
      default: false,
    },
    link: {
      type: String,
      required: false,
    },
  },
  mounted() {
    this.Hover ? (this.styleHover = this.Hover) : (this.styleHover = this.type);
  },
};
</script>
<style scoped>
.rounded-lg-circle {
  width: 54.9px;
  /* Definir a largura do elemento */
  height: 54px;
  border-radius: 50%;
}

i {
  margin-left: 17px;
  font-size: 20px;
}

.icon {
  color: black;
  border: 1px solid transparent;
}

div > .icone:hover {
  .icon {
    color: white;
  }
}
</style>