<template>
  <div>
    <i class="fas fa-envelope mr-2" v-if="showLeftMailIcon"></i>
    <a :href="`mailto:${emailAddress}`" class="ms-2" title="Escrever um email">
      {{ emailAddress }}
      <i class="fas fa-external-link-alt ml-1 text-gray"></i
    ></a>
  </div>
</template>
<script>
export default {
  name: "email-link",
  props: {
    emailAddress: {
      type: String,
      required: true
    },
    showLeftMailIcon: {
      type: Boolean,
      default: true
    }
  }
};
</script>
<style scoped>
i.fa-external-link-alt {
  font-size: 0.75rem !important;
}
</style>
