<template>
  <main class="mt-0">
    <section>
      <div class="page-header min-vh-100">
        <transition appear name="bounce">
          <div class="container" style="max-width: 480px;" v-if="show" ref="modal_confirmation">
            <div class="row">
              <div class="col-12 d-flex align-items-center ">
                <div class="card card-plain col-12 col-md-12 col-lg-12 d-flex align-items-center">
                  <div class="px-5 pt-5 pb-3 card-header text-center">
                    <h4 class="font-weight-bolder fs-4">Esqueceu sua senha?</h4>
                    <p class="mb-0 fs-6 pb-3 text-sm">Digite o endereço de email de sua conta e nós enviaremos um link
                      para resetar sua senha.</p>
                    <div class="card-body p-0">
                      <form role="form" @submit.prevent=handleForgot>
                        <!--Criar função de Handle Forgot para verificar se o email corresponde-->
                        <div class="mb-3">
                          <argon-input 
                            type="email" 
                            placeholder="Email da conta" 
                            name="email" 
                            size="lg" 
                            v-model.trim="model.email"/>
                        </div>
                        <div class="text-center pb-4">
                          <argon-button 
                            v-if="showButton"
                            class="mt-0 fs-4" 
                            :style="{ 'background': $store.getters.colorGradient({ color: 'primary', gradient: -20 }) }"
                            variant="gradient" 
                            color="success" 
                            fullWidth
                            size="lg">Enviar
                          </argon-button>
                          <span v-else class="text-sm text-muted">
                            Não recebeu o email?
                          <argon-button
                              class="mt-0 fs-4" 
                              variant="gradient" 
                              color="success" 
                              fullWidth
                              size="lg">
                              Enviar novamente</argon-button
                            ></span
                          >
                        </div>
                      </form>
                    </div>
                    <div class="px-0 py-1 text-start card-footer px-lg-0 pt-1">
                      <button class="px-1 button" @click="changeShowStatus">
                        <router-link class="nav-link px-0" to="/login">
                          <p class="mx-auto text-sm forgot-password">
                            Voltar para login
                          </p>
                        </router-link>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </section>
  </main>
</template>

<script>
import api from "@/services/api";
const body = document.getElementsByTagName("body")[0];
import { Transition } from 'vue';

export default {
  name: "forgot-password",
  data() {
    return {
      model: {
        email: "",
      },
      show: true,
      showButton: true
    };
  },
  components: {
    Transition,
  },
  created() {
    this.$store.state.hideConfigButton = true;
    this.$store.state.showNavbar = false;
    this.$store.state.showSidenav = false;
    this.$store.state.showFooter = false;
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.$store.state.showNavbar = true;
    this.$store.state.showSidenav = true;
    this.$store.state.showFooter = true;
    body.classList.add("bg-gray-100");
  },
  methods: {
    changeShowStatus() {
      this.show = !this.show
    },
    async handleForgot() {
      if (!this.model.email) {
        this.$toast.error("Preencha o email para continuar");
      } else {
      const loader = this.$loading.show({
        container: this.$refs.modal_confirmation,
        width: 90,
        height: 90,
        opacity: 0,
        color: "black",
      });
      try {
       await api.post("/users/forgot-password", this.model);
       this.showButton = false;
       this.$toast.success("Email de redefinição enviado com sucesso");
    } catch (error) {
      this.$error.errorMessageToast("Ocorreu um erro ao enviar o email de recuperação", error);
    }   finally {
      loader.hide();
      }
     }
    }
  }
};
</script>
<style >
body,
html {
  height: 100%;
  margin: 0;
  padding: 0;
}

.forgot-password:hover {
  color: red;
  /*cor temporaria?*/
  transition: 0.3s;
}

.button {
  border: none;
  background: transparent;
}

.bounce-enter-active {
  animation: bounce-in 0.6s;
}

.bounce-leave-active {
  animation: bounce-in 0.6s reverse;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}
</style>
