<template>
    <div class="py-4 container-fluid">
        <div class="row mb-8">
            <div class="col-lg-12">
                <div class="row">
                    <div class="col-12">
                        <slot></slot>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "page-header",
};
</script>
  