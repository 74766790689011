import { useToast } from "vue-toastification";
import { displayConsoleLog } from "../env/controllers";
import store from "@/store/index.js";

export const ErrorMessage = {
    install(Vue) {
        Vue.config.globalProperties.$error = {
            errorMessageToast(standardMessage, error) {
                const genericError = standardMessage || "Ocorreu um erro inesperado. Tente novamente mais tarde.";
                const hasErrorResponseData = error.response && error.response.data;
                let customError = genericError;
                if (hasErrorResponseData) {
                    const data = error.response.data;
                    if (data[0] && data[0].message) customError = data[0].message;
                    else if (data.message) customError = data.message;
                    else if (data.errors)
                        if (data.errors[0])
                            if (data.errors[0].message)
                                customError = data.errors[0].message;
                }
                if (displayConsoleLog) console.log(error);
                if (customError === 'E_UNAUTHORIZED_ACCESS: Unauthorized access')
                    store.dispatch('logout')
                const messageError = hasErrorResponseData ? customError : genericError;
                useToast().error(messageError);
                return messageError;
            },
            errorMessage(standardMessage, error) {

                const genericError = standardMessage || "Ocorreu um erro inesperado. Tente novamente mais tarde.";
                const hasErrorResponseData = error.response && error.response.data;
                let customError = genericError;
                if (hasErrorResponseData) {
                    const data = error.response.data;
                    if (data[0] && data[0].message) customError = data[0].message;
                    else if (data.message) customError = data.message;
                    else if (data.errors)
                        if (data.errors[0])
                            if (data.errors[0].message)
                                customError = data.errors[0].message;
                }
                if (customError === 'E_UNAUTHORIZED_ACCESS: Unauthorized access')
                    store.dispatch('logout')
                const messageError = hasErrorResponseData ? customError : genericError;
                return messageError;
            }
        }
    }
}
