<template>
  <button class="btn mb-0" @mouseenter="() => hover = true" @mouseleave="() => hover = false"
    :class="getClasses(variant, color, size, fullWidth, active), getColor">
    <i v-if="btnIconOnly" :class="iconClass" />
    <slot v-else />
  </button>
</template>

<script>
export default {
  name: "argon-button",
  props: {
    btnIconOnly: {
      type: Boolean,
      default: false,
    },
    iconClass: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "info",
    },
    size: {
      type: String,
      default: "md",
    },
    variant: {
      type: String,
      default: "fill",
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    link: String,
  },
  data() {
    return {
      hover: false,
    };
  },
  computed: {
    getColor() {
      const variant = this.variant
      if (this.hover)
        return `btn-${this.color}`;
      if (variant === "gradient") {
        return `bg-gradient-${this.color}`;
      } else if (variant === "outline") {
        return `btn-outline-${this.color}`;
      } else {
        return `btn-${this.color}`;
      }
    }
  },
  methods: {
    getClasses: (variant, color, size, fullWidth, active) => {
      let sizeValue, fullWidthValue, activeValue;

      sizeValue = size ? `btn-${size}` : null;

      fullWidthValue = fullWidth ? `w-100` : null;

      activeValue = active ? `active` : null;

      return `${sizeValue} ${fullWidthValue} ${activeValue} `;
    },
  },
};
</script>
