export const COLOR = '@Portal-color'
export const SECONDARY_COLOR = "@secondary-color";
export const TOKEN_KEY = "@Portal-Token";
export const ROLE = "@Portal-Role";
export const ROLEMIRROR = "@Portal-RoleMirror";
export const FORMCALLSI = "@form-calls-incubacao";
export const FORMCALLSP = "@form-calls-pre-incubacao";
export const FORMCALLSO = "@form-calls-olimpiada";
export const PREFIX = "@prefix-url";
export const PREFIXMIRROR = "@prefixMirror-url";
export const USER_ID = "@user-Id";
export const USER_THEME = "@user-theme";
export const PROFILE_PICTURE = "@profile-picture";
export const SIDENAV_IMG = "@sidenav-img";
export const PREFIX_URL = "@prefix-url";
export const USER_NAME = "@user-name";
export const POP_UP_EXPIRE = "@pop-up-expire";