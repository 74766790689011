import {
  TOKEN_KEY,
  ROLE,
  ROLEMIRROR,
  FORMCALLSI,
  FORMCALLSP,
  FORMCALLSO,
  PREFIX,
  PREFIXMIRROR,
  USER_ID,
  USER_THEME,
  COLOR,
  PROFILE_PICTURE,
  USER_NAME,
  SECONDARY_COLOR,
  POP_UP_EXPIRE
} from "@/store/cacheConstants";

export const getToken = localStorage.getItem(TOKEN_KEY) || undefined;
const getRole = localStorage.getItem(ROLE) || undefined;
const getRoleMirror = localStorage.getItem(ROLEMIRROR) || undefined;
const getPrefix = localStorage.getItem(PREFIX) || undefined;
const getFormCallsI = localStorage.getItem(FORMCALLSI) || undefined;
const getFormCallsP = localStorage.getItem(FORMCALLSP) || undefined;
const getFormCallsO = localStorage.getItem(FORMCALLSO) || undefined;
const getPrefixMirror = localStorage.getItem(PREFIXMIRROR) || undefined;
const getUserId = localStorage.getItem(USER_ID) || undefined;
const getTheme = localStorage.getItem(USER_THEME) || undefined;
const getColor = localStorage.getItem(COLOR) || "#01c38e";
const getProfilePicture = localStorage.getItem(PROFILE_PICTURE) || undefined;
const getSecondaryColor = localStorage.getItem(SECONDARY_COLOR) || "#132d46";
const getUserName = localStorage.getItem(USER_NAME) || undefined;
export const auth = {
  state: () => ({
    token: getToken,
    role: getRole,
    roleMirror: getRoleMirror,
    prefix: getPrefix,
    formCalls: {
      formcallsi: getFormCallsI,
      formcallsp: getFormCallsP,
      formcallso: getFormCallsO,
    },
    prefixMirror: getPrefixMirror,
    userId: getUserId,
    theme: getTheme,
    color: getColor,
    secondary_color: getSecondaryColor,
    profilePicture: getProfilePicture,
    userName: getUserName,
    status: 'open'
  }),
  mutations: {
    storeProfilePicture(state, data) {
      state.profilePicture = data;
      localStorage.setItem(PROFILE_PICTURE, data);
    },
    dropProfilePicture(state) {
      state.profilePicture = undefined;
      localStorage.removeItem(PROFILE_PICTURE);
    },
    storeUserName(state, data) {
      state.userName = data;
      localStorage.setItem(USER_NAME, data);
    },
    storeUserNameMirror(state, data) {
      state.userName = data;
    },
    storeProfilePictureMirror(state, data) {
      state.profilePicture = data;
    },
    dropUserName(state) {
      state.userName = undefined;
      localStorage.removeItem(USER_NAME);
    },
    storeRole(state, data) {
      state.role = data;
      localStorage.setItem(ROLE, data);
    },
    storePrefix(state, data) {
      state.prefix = data;
      localStorage.setItem(PREFIX , data);
    },
    storeFormCalls(state, { key, data }) {
      state.formCalls[key] = data;
      localStorage.setItem(key, data);
    },
    storePrefixMirror(state, data) {
      state.prefixMirror = data;
      localStorage.setItem(PREFIXMIRROR , data);
    },
    storeRoleMirror(state, data) {
      state.roleMirror = data;
      localStorage.setItem(ROLEMIRROR, data);
    },
    dropRole(state) {
      state.role = undefined;
      localStorage.removeItem(ROLE);
    },
    dropRoleMirror(state) {
      state.roleMirror = undefined;
      localStorage.removeItem(ROLEMIRROR);
    },
    dropFormCalls(state, key) {
      state.formCalls[key] = undefined;
      localStorage.removeItem(key);
    },
    storeToken(state, data) {
      state.token = data;
      localStorage.setItem(TOKEN_KEY, data);
    },
    dropToken(state) {
      state.token = undefined;
      localStorage.removeItem(TOKEN_KEY);
    },
    storeUserId(state, data) {
      state.userId = data;
      localStorage.setItem(USER_ID, data);
    },
    dropUserId(state) {
      state.userId = undefined;
      localStorage.removeItem(USER_ID);
    },
    storeTheme(state, data) {
      state.theme = data;
      localStorage.setItem(USER_THEME, data);
    },
    dropTheme(state) {
      state.theme = undefined;
      localStorage.removeItem(USER_THEME);
    },
    storeColor(state, data) {
      data ? (state.color = data) : (state.color = "#01c38e");
      localStorage.setItem(COLOR, state.color);
    },
    dropColor(state) {
      state.color = "#01c38e";
      localStorage.removeItem(COLOR);
    },
    storeSecondaryColor(state, data) {
      data ? (state.secondary_color = data) : (state.secondary_color = "#132d46");
      localStorage.setItem(SECONDARY_COLOR, state.secondary_color);
    },
    dropSecondaryColor(state) {
      state.secondary_color = "#132d46";
      localStorage.removeItem(SECONDARY_COLOR);
    }
  },
  getters: {
    profilePicture(state) {
      return state.profilePicture || "/img/users/default_profile_picture.jpg";
    },
    role(state) {
      return state.role;
    },
    roleMirror(state) {
      return state.roleMirror;
    },
    formCalls: (state) => (key) => {
      return state.formCalls[key];
    },
    userName(state) {
      return state.userName;
    },
    token(state) {
      return state.token;
    },
    userId(state) {
      return state.userId;
    },
    theme(state) {
      return state.theme;
    },
    color(state) {
      return state.color;
    },
    brightColor: (state) => (payload) => {
      let H = payload.color
      // Convert hex to RGB first
      let r = 0,
        g = 0,
        b = 0;
      if (H.length == 4) {
        r = "0x" + H[1] + H[1];
        g = "0x" + H[2] + H[2];
        b = "0x" + H[3] + H[3];
      } else if (H.length == 7) {
        r = "0x" + H[1] + H[2];
        g = "0x" + H[3] + H[4];
        b = "0x" + H[5] + H[6];
      }
      // Then to HSL
      r /= 255;
      g /= 255;
      b /= 255;
      let cmin = Math.min(r, g, b),
        cmax = Math.max(r, g, b),
        delta = cmax - cmin,
        h = 0,
        s = 0,
        l = 0;

      if (delta == 0) h = 0;
      else if (cmax == r) h = ((g - b) / delta) % 6;
      else if (cmax == g) h = (b - r) / delta + 2;
      else h = (r - g) / delta + 4;

      h = Math.round(h * 60);

      if (h < 0) h += 360;

      l = (cmax + cmin) / 2;
      s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
      s = +(s * 100).toFixed(1);
      l = +(l * 100).toFixed(1);
      l += payload.gradient
      const hslGradient = "hsl(" + h + "," + s + "%," + l + "%)"
      return `${hslGradient})`
    },
    colorGradient: (state) => (payload) => {
      let H;
      if (payload.color === 'primary')
        H = state.color;
      else
        H = state.secondary_color;
      // Convert hex to RGB first
      let r = 0,
        g = 0,
        b = 0;
      if (H.length == 4) {
        r = "0x" + H[1] + H[1];
        g = "0x" + H[2] + H[2];
        b = "0x" + H[3] + H[3];
      } else if (H.length == 7) {
        r = "0x" + H[1] + H[2];
        g = "0x" + H[3] + H[4];
        b = "0x" + H[5] + H[6];
      }
      // Then to HSL
      r /= 255;
      g /= 255;
      b /= 255;
      let cmin = Math.min(r, g, b),
        cmax = Math.max(r, g, b),
        delta = cmax - cmin,
        h = 0,
        s = 0,
        l = 0;

      if (delta == 0) h = 0;
      else if (cmax == r) h = ((g - b) / delta) % 6;
      else if (cmax == g) h = (b - r) / delta + 2;
      else h = (r - g) / delta + 4;

      h = Math.round(h * 60);

      if (h < 0) h += 360;
      if (h < 0) h += 360;

      l = (cmax + cmin) / 2;
      s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
      s = +(s * 100).toFixed(1);
      l = +(l * 100).toFixed(1);
      const hslOrigin = "hsl(" + h + "," + s + "%," + l + "%)"
      l += payload.gradient
      const hslGradient = "hsl(" + h + "," + s + "%," + l + "%)"
      return `linear-gradient(to right, ${hslOrigin}, ${hslGradient})`
    }
  },
  actions: {
    setProfilePicture({ commit }, data) {
      commit("storeProfilePicture", data);
    },
    setColor({ commit }, data) {
      commit('storeColor', data.color)
      commit('storeSecondaryColor', data.secondary_color)
    },
    login({ commit }, data) {
      commit('storeToken', data.auth.token)
      commit('storeRole', data.roles)
      commit('storeUserId', data.user_id)
      commit("storeUserName", data.name);
      commit('storeColor', data.color)
      commit('storeSecondaryColor', data.secondary_color)
      data.sidenavUrl ? commit('storeSidenavImage', data.sidenavUrl) : commit('storeSidenavImage', '/img/brand/logo_portal.png')
      data.profile_picture ? commit('storeProfilePicture', data.profile_picture) : commit('storeProfilePicture', '/img/users/default_profile_picture.jpg')
    },
    logout({ commit, dispatch }) {

      commit('dropToken')
      commit('dropRole')
      commit('dropRoleMirror')
      commit('dropFormCalls')
      commit('dropUserId')
      //commit('dropTheme')
      commit("dropUserName");
      //commit('dropSidenavImage')
      commit('dropProfilePicture')
      commit('dropPopUpDateExpire')
    }
  }
}