<template>
  <div v-show="this.$store.state.layout === 'default'" class="min-height-300 position-absolute w-100"
    :class="`${this.$store.state.darkMode ? 'bg-transparent' : 'bg-success'}`" />
  <aside class="my-3 overflow-auto border-0 sidenav navbar navbar-vertical navbar-expand-xs border-radius-xl" :class="`${this.$store.state.isRTL
    ? 'me-3 rotate-caret fixed-end'
    : 'fixed-start ms-3'
    } 
              ${this.$store.state.layout === 'landing'
      ? 'bg-transparent shadow-none'
      : ' '
    } ${this.$store.state.sidebarType}`" id="sidenav-main">
    <div class="sidenav-header">
      <i class="top-0 p-3 cursor-pointer fas fa-times text-secondary opacity-5 position-absolute end-0 d-none d-xl-none"
        aria-hidden="true" id="iconSidenav"></i>
      <router-link class="m-0 navbar-brand" to="/">
        <img :src="this.$store.state.darkMode ||
          this.$store.state.sidebarType === 'bg-default'
          ? logoWhite
          : logo
          " class="navbar-brand-img h-100" alt="main_logo" />
        <span class="ms-2 font-weight-bold me-2">Argon Dashboard 2</span>
      </router-link>
    </div>
    <hr class="mt-0 horizontal dark" />
    <sidenav-list :cardBg="custom_class" />
  </aside>
  <router-view />
</template>
<script>
import SidenavList from "./SidenavList.vue";
import logo from "@/assets/img/default_profile_picture.jpg";
import logoWhite from "@/assets/img/default_profile_picture.jpg";

export default {
  name: "index",
  components: {
    SidenavList
  },
  data() {
    return {
      logo,
      logoWhite
    };
  },
  props: ["custom_class", "layout"]
};
</script>