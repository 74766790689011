<template>
  <div
    :style="{
      background: $store.getters.colorGradient({
        color: 'primary',
        gradient: -20,
      }),
    }"
  >
    <nav
      class="navbar navbar-main navbar-expand-lg container-fluid px-3 pt-3 pb-3 shadow-none border-radius-xl"
      v-bind="$attrs"
      id="navbarBlur"
      data-scroll="true"
    >
      <div class="px-4 py-1 pt-3 pb-4 backgr container bg-white">
        <!--Imagem do cliente-->
        <nav aria-label="breadcrumb">
          <img class="img" :src="$store.state.sidenav.sidenavImage" />
        </nav>
        <!--Trecho copiado de PageLaoyout/Navbar.vue-->
        <button
          @click="changeShowNav"
          class="shadow-none navbar-toggler ms-2"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navigation"
          aria-controls="navigation"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="mt-2 navbar-toggler-icon">
            <span class="navbar-toggler-bar bar1"></span>
            <span class="navbar-toggler-bar bar2"></span>
            <span class="navbar-toggler-bar bar3"></span>
          </span>
        </button>

        <div class="collapse navbar-collapse" id="navigation" :class="{ 'd-block': display}">
          <ul class="navbar-nav ms-auto text-left">
            <br />
            <li class="nav-item" 
              v-if="domain_prefix !== 'portalhubinovacao' && domain_prefix !== 'localhost'"
              >
              <router-link class="nav-link me-2 fs-4" to="/">
                <i class="ni ni-tv-2 me-1 fw-bold"></i>
                Início
              </router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link me-2 fs-4" to="/login">
                <i class="fas fa-sign-in-alt me-1"></i>
                Entrar
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <div>
      <router-view />
    </div>
    <footer class="footer px-4 bg-transparent py-3">
      <div class="row align-items-center justify-content-lg-between px-1">
        <div class="col-lg-6 mt-2 mt-lg-0 fs-4">
          <div class="copyright text-center text-lg-start text-white">
            © {{ year }}
            Powered by
            <a
              href="https://starsaceleradora.com.br/"
              class="fw-bolder stars"
              target="_blank"
              >Stars Aceleradora</a
            >
          </div>
        </div>
        <div class="col-lg-6 mt-3 mt-lg-0">
          <ul
            class="nav nav-footer justify-content-center justify-content-lg-end"
          >
            <li class="icons">
              <a
                href="https://www.facebook.com/AceleradoraStars/"
                class="nav-link"
                target="_blank"
                ><i class="fa fas fa-facebook text-lg text-white"></i
              ></a>
            </li>
            <li class="icons">
              <a
                href="https://www.instagram.com/starsaceleradora/?hl=pt-br"
                class="nav-link"
                target="_blank"
                ><i class="fa fas fa-instagram text-lg text-white"></i
              ></a>
            </li>
            <li class="icons">
              <a
                href="https://www.linkedin.com/company/starsaceleradora?originalSubdomain=br"
                class="nav-link"
                target="_blank"
                ><i class="fa fas fa-linkedin text-lg text-white"></i
              ></a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
import { mapMutations, mapActions } from "vuex";
import api from "@/services/api";

export default {
  name: "navbar",
  data() {
    return {
      year: new Date().getFullYear(),
      display: false,
      domain_prefix: window.location.hostname.split(".")[0]
    };
  },
  props: ["minNav", "textWhite"],
  created() {
    this.minNav;
  },
  async mounted() {
    try {
      const domain_prefix = window.location.hostname.split(".")[0];
      if (
        !(domain_prefix === this.$store.state.sidenav.prefixUrl)
      ) {
        let url = `public/rectory-theme/${domain_prefix}`
        if (this.$route.params.id) url+= `/${this.$route.id}`
        const { data } = await api.get(url);
        data.sidenav_url
          ? this.$store.dispatch("storeSidenavImage", data.sidenav_url)
          : this.$store.commit(
              "storeSidenavImage",
              "/img/brand/logo_portal.png"
            );
        this.$store.dispatch("setColor", {
          color: data.color,
          secondary_color: data.secondary_color,
        });
        this.$store.dispatch("storePrefixUrl", domain_prefix);
      }
    } catch (error) {
      this.$error.errorMessageToast(
        "Não foi possível carregar informações",
        error
      );
    }
  },
  methods: {
    ...mapMutations(["navbarMinimize", "toggleConfigurator"]),
    ...mapActions(["toggleSidebarColor"]),

    toggleSidebar() {
      this.toggleSidebarColor("bg-white");
      this.navbarMinimize();
    },
    changeShowNav() {
      this.display = !this.display;
    }
  },
  components: {},
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
};
</script>
<style>
.backgr {
  border-radius: 10px;
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.1);
}

.nav-item:hover {
  background-color: rgba(211, 211, 211, 0.5);
  transition: 0.5s;
  border-radius: 10px;
}
</style>
<style scoped>
.img {
  height: 70px;
}

.stars {
  color: #fd7e14;
  transition: 0.5s ease;
}

.stars:hover {
  color: #ff4200;
}

.icons:hover {
  animation: scala 1s;
}

@keyframes scala {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}
a.nav-link {
  display: flex;
  align-items: center;
}
</style>
