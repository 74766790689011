<template>
  <div class="card text-center flex-grow-1 mx-3" :class="[
    { 'card-lift--hover': hover },
    { shadow: shadow },
    { [`shadow-${shadowSize}`]: shadowSize },
    { [`bg-gradient-${gradient}`]: gradient },
    { [`bg-${type}`]: type }
  ]">

    <div class="card-profile">
      <div class="card-profile-image">
        <slot name="profilePicture"></slot>
      </div>
    </div>

    <div class="card-body mt-8 pt-0 pb-3" :class="bodyClasses" v-if="!noBody" :style="bodyStyle">
      <slot name="body"></slot>
    </div>

    <div class="card-header" :class="headerClasses" v-if="$slots.header">
      <slot name="header"></slot>
    </div>

    <slot v-if="noBody"></slot>

    <div class="card-footer text-center pt-0 border-2 pt-3 pb-3" :class="footerClasses" v-if="$slots.footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: "card",
  props: {
    type: {
      type: String,
      description: "Card type"
    },
    gradient: {
      type: String,
      description: "Card background gradient type (warning,danger etc)"
    },
    hover: {
      type: Boolean,
      description: "Whether card should move on hover"
    },
    shadow: {
      type: Boolean,
      description: "Whether card has shadow"
    },
    shadowSize: {
      type: String,
      description: "Card shadow size"
    },
    noBody: {
      type: Boolean,
      default: false,
      description: "Whether card should have wrapper body class"
    },
    bodyStyle: {
      type: [String, Object, Array],
      description: "Card body style"
    },
    bodyClasses: {
      type: [String, Object, Array],
      description: "Card body css classes"
    },
    headerClasses: {
      type: [String, Object, Array],
      description: "Card header css classes"
    },
    footerClasses: {
      type: [String, Object, Array],
      description: "Card footer css classes"
    }
  },
};
</script>
<style></style>
