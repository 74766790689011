<template>
  <div class="mt-4 card move-on-hover">
    <div class="card-body">
      <div class="d-flex">
        <p class="my-auto">Messages</p>
        <div class="ms-auto">
          <div class="avatar-group">
            <a href="javascript:;" class="border-0 avatar avatar-sm rounded-circle" data-bs-toggle="tooltip"
              data-bs-placement="top" title="2 New Messages">
              <img alt="Image placeholder" src="@/assets/img/default_profile_picture.jpg" />
            </a>
            <a href="javascript:;" class="border-0 avatar avatar-sm rounded-circle" data-bs-toggle="tooltip"
              data-bs-placement="top" title="1 New Message">
              <img alt="Image placeholder" src="@/assets/img/default_profile_picture.jpg" />
            </a>
            <a href="javascript:;" class="border-0 avatar avatar-sm rounded-circle" data-bs-toggle="tooltip"
              data-bs-placement="top" title="13 New Messages">
              <img alt="Image placeholder" src="@/assets/img/default_profile_picture.jpg" />
            </a>
            <a href="javascript:;" class="border-0 avatar avatar-sm rounded-circle" data-bs-toggle="tooltip"
              data-bs-placement="top" title="7 New Messages">
              <img alt="Image placeholder" src="@/assets/img/default_profile_picture.jpg" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "card-message",
};
</script>
