<template>
    <div class="container-fluid transition position-absolute">
        <div class="a">
            <slot></slot>
        </div>
        <content-footer></content-footer>
    </div>
</template>
<script>
import ContentFooter from "@/components/ContentFooter.vue";
export default {
    name: "page-content",
    components: {
        ContentFooter
    }
};
</script>
<style scoped>
.transition {
    transition: 0.5s;
}
.a {
    min-height: 58vh;
}
</style>