import { SIDENAV_IMG, PREFIX_URL } from "./cacheConstants";
const sidenavImg = localStorage.getItem(SIDENAV_IMG);
const prefixUrl = localStorage.getItem(PREFIX_URL);
const website_url = localStorage.getItem("website_url");
export const sidenav = {
  state: {
    sidebarType: "bg-white",
    sidenavImage: sidenavImg || "/img/brand/logo_portal.png",
    prefixUrl: prefixUrl || "portalhubinovacao",
    website_url: website_url || "",
    showSidenav: null,
  },
  mutations: {
    storeSidenavImage(state, payload) {
      state.sidenavImage = payload;
      localStorage.setItem(SIDENAV_IMG, payload);
    },
    dropSidenavImage(state) {
      state.sidenavImage = undefined;
      localStorage.removeItem(SIDENAV_IMG);
    },
    storePrefixUrl(state, payload) {
      state.prefixUrl = payload;
      localStorage.setItem(PREFIX_URL, payload);
    },
    dropPrefixUrl(state) {
      state.prefixUrl = undefined;
      localStorage.removeItem(PREFIX_URL);
    },
    storeWebsiteUrl(state, payload) {
      state.website_url = payload;
      localStorage.setItem("website_url", payload);
    },
    dropWebsiteUrl(state) {
      state.website_url = undefined;
      localStorage.removeItem("website_url");
    },
  },
  getters: {
    sidenavImage(state) {
      return state.sidenavImage;
    },
    prefixUrl(state) {
      return state.prefixUrl;
    },
  },
  actions: {
    storeSidenavImage(state, payload) {
      state.commit("storeSidenavImage", payload);
    },
    dropSidenavImage(state) {
      state.commit("dropSidenavImage");
    },
    storePrefixUrl(state, payload) {
      state.commit("storePrefixUrl", payload);
    },
    dropPrefixUrl(state) {
      state.commit("dropPrefixUrl");
    },
    storeWebsiteUrl(state, payload) {
      state.commit("storeWebsiteUrl", payload);
    },
    dropWebsiteUrl(state) {
      state.commit("dropWebsiteUrl");
    },
  },
};
