<template>
  <main class="mt-0">
    <section>
      <div class="page-header min-vh-100">
        <transition appear name="bounce">
          <div class="container">
            <div class="row">
              <div class="col-12 d-flex align-items-center">
                <div
                  class="card card-plain col-12 col-md-12 col-lg-12 d-flex align-items-center"
                  style="border: 0px"
                >
                  <div class="px-5 pt-5 pb-4 card-header text-center">
                    <h1 class="font-weight-bolder">Seja bem-vindo!</h1>
                    <p class="mb-0 fs-3 pb-3">
                      Página de acesso ao Portal Hub de Inovação
                    </p>
                    <div class="card-body p-0">
                      <form role="form" @submit.prevent="handleLogin">
                        <!--Criar função de Handle Login para verificar o email e a senha-->
                        <div class="mb-3">
                          <argon-input
                            type="email"
                            placeholder="Email"
                            name="email"
                            size="lg"
                            v-model="user.email"
                          />
                        </div>
                        <div class="mb-3">
                          <argon-input
                            type="password"
                            placeholder="Senha"
                            name="password"
                            size="lg"
                            v-model="user.password"
                          />
                        </div>
                        <argon-switch id="rememberMe">Lembrar-me</argon-switch>
                        <div class="text-center pb-4">
                          <argon-button
                            class="mt-4 fs-4 login-button"
                            variant="gradient"
                            :style="{ 'background': $store.getters.colorGradient({ color: 'primary', gradient: -20 }) }"
                            fullWidth
                            size="lg"
                            >Entrar</argon-button
                          >
                        </div>
                      </form>
                    </div>
                    <div class="px-0 py-1 text-end card-footer px-lg-0 pt-1">
                      <button class="px-1 button" @click="changeShowStatus">
                        <router-link
                          class="nav-link px-0"
                          to="/forgot-password"
                        >
                          <p class="mx-auto text-sm forgot-password mb-0">
                            Esqueceu sua senha?
                          </p>
                        </router-link>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </section>
  </main>
</template>

<script>
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonSwitch from "@/components/ArgonSwitch.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import { Transition } from "vue";
import api from "@/services/api";
export default {
  data() {
    return {
      user: {
        email: "",
        password: "",
      },
    };
  },
  name: "login",
  components: {
    ArgonInput,
    ArgonSwitch,
    ArgonButton,
    Transition,
  },

  methods: {
    changeShowStatus() {
      this.show = !this.show;
    },
    async handleLogin() {
      const loader = this.$loading.show({
        container: this.$refs.modal_confirmation,
        width: 90,
        height: 90,
        opacity: 0,
        color: "white",
      });
      try {
        const mapRoles = {
          admin: "/admin",
          rectory: "/reitoria",
          incubator: "/incubadora",
          startup: "/startup",
          member: "/membro",
          guest: "/convidado",
        };

        const { data } = await api.post("/sessions", this.user);
        this.$store.dispatch("login", data);
        this.$toast.success("Bem-vindo!");
        this.$router.push(mapRoles[data.roles]);
      } catch (error) {
        this.$error.errorMessageToast("Ocorreu um erro ao fazer login", error);
      }
      loader.hide();
    },
  },
};
</script>
<style>
body,
html {
  height: 100%;
  margin: 0;
  padding: 0;
}

.forgot-password:hover {
  color: red;
  /*cor temporaria?*/
  transition: 0.1s;
}

.button {
  border: none;
  background: transparent;
}

.login-button{
  background-color: #01c38e;
}

.bounce-enter-active {
  animation: bounce-in 0.6s;
}

.bounce-leave-active {
  animation: bounce-in 0.6s reverse;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}
</style>
