import { createStore } from "vuex";
import { auth } from "@/store/auth";
import { sidenav } from "@/store/sidenav";
import { popup } from "@/store/popup";
const store = createStore({
  modules: {
    auth: auth,
    sidenav: sidenav,
    popup: popup,
  },
  state: {},
  mutations: {
    storeRole(state, data) {
      state.role = data;
    },
    storeRoleMirror(state, data) {
      state.roleMirror = data;
    },
    storePrefix(state, data) {
      state.prefix = data;
    },
    storeFormCalls(state, { key, data }) {
      state.formCalls = data;
    },
    dropFormCalls(state, key) {
      state.formCalls = undefined;
    },
    storePrefixMirror(state, data) {
      state.prefixMirror = data;
    },
    dropRole(state) {
      state.role = undefined;
    },
    dropRoleMirror(state) {
      state.roleMirror = undefined;
    },
    toggleConfigurator(state) {
      state.showConfig = !state.showConfig;
    },
    navbarMinimize(state) {
      state.showSidenav = !state.showSidenav;
    },
    sidebarType(state, payload) {
      state.sidebarType = payload;
    },
  },
  actions: {
    storeRoleAdmin(state) {
      state.commit.storeRole("admin");
    },
    storeRoleRectory(state) {
      state.commit.storeRole("rectory");
    },
    storeRoleIncubator(state) {
      state.commit.storeRole("incubator");
    },
    storeRoleStartup(state) {
      state.commit.storeRole("startup");
    },
    storeRoleMentor(state) {
      state.commit.storeRole("mentor");
    },
    storeRoleMember(state) {
      state.commit.storeRole("member");
    },
    storeRoleGuest(state) {
      state.commit.storeRole("guest");
    },
    storePrefix(state, data) {
      state.commit.storeRole(data);
    },
    storePrefixMirror(state, data) {
      state.commit.storePrefixMirror(data);
    },
    storeRoleMirror(state, data) {
      state.commit.storeRoleMirror(data);
    },
    storeFormCalls({ commit }, { key, data }) {
      commit('storeFormCalls', { key, data });
    },    
    dropFormCalls({ commit }, key) {
      commit('dropFormCalls', key);
    },    
    logOut(state) {
      state.commit.dropRole();
    },
    dropRoleMirror(state) {
      state.commit.dropRoleMirror();
    },
    toggleSidebarColor(state, payload) {
      state.commit("sidebarType", payload);
    },
  },
  getters: {},
});

export default store;
