<template>
  <div class="container-width" :style="{ 'background': $store.getters.colorGradient({ color: 'primary', gradient: -20 }) }">
    <nav class="navbar navbar-main navbar-expand-lg container-fluid px-3 pt-3 pb-3 shadow-none border-radius-xl"
      v-bind="$attrs" id="navbarBlur" data-scroll="true">
      <div class="px-4 py-1 pt-3 pb-4 backgr bg-white container">
        <span class="navbar-brand m-auto">
          <img :src="$store.state.sidenav.sidenavImage" class="custom-logo cursor-pointer"
            :title="$store.state.sidenav.prefixUrl" :alt="`Logo da ${$store.state.sidenav.prefixUrl.toLocaleUpperCase()}`"
            style="max-height: 75px" @click="
              $store.state.sidenav.website_url
                ? openLink($store.state.sidenav.website_url)
                : null
              " />
        </span>
      </div>
    </nav>
    <!-- Page content -->
    <div>
      <router-view />
    </div>
  </div>
</template>
<script>
import api from "@/services/api";
export default {
  name: "blank-layout",
  components: {},
  data() {
    return {
      year: new Date().getFullYear(),
      website_url: "",
    };
  },
  async beforeMount() {
    try {
      const domain_prefix = window.location.hostname.split(".")[0];
      if (
        !(domain_prefix === this.$store.state.sidenav.prefixUrl)
      ) {
        let url = `public/rectory-theme/${domain_prefix}`
        if (this.$route.id) url+= `/${this.$route.id}`
        const { data } = await api.get(
          url
        );
        data.sidenav_url
          ? this.$store.dispatch("storeSidenavImage", data.sidenav_url)
          : this.$store.commit(
            "storeSidenavImage",
            "/img/brand/logo_portal.png"
          );

        this.$store.dispatch("setColor", { color: data.color, secondary_color: data.secondary_color })

        this.$store.dispatch("storePrefixUrl", domain_prefix);

        data.website_url
          ? this.$store.dispatch("storeWebsiteUrl", data.website_url)
          : this.$store.dispatch("storeWebsiteUrl", "");
      }
    } catch (error) {
      this.$error.errorMessageToast(
        "Não foi possível carregar informações",
        error
      );
    }
  },
  methods: {
    openLink(link) {
      window.open(link, "_blank", "noreferrer noopener");
    },
  },
};
</script>
<style scoped>
.vh-98 {
  height: 98vh;
}

.cursor-pointer {
  cursor: pointer;
}

.navbar .navbar-brand img {
  height: 65px;
}
</style>
<style>
html {
  position: relative;
  min-height: 100%;
}

.container-width {
  height: 100%;
  min-height: 100vh;
}
</style>
