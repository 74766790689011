import "./assets/js/nav-pills.js";
import "./assets/scss/argon-dashboard.scss";

import VueGoogleMaps from "@fawmi/vue-google-maps";
import globalDirectives from "./globalDirectives";
import VueGtag from "vue-gtag";

import { LoadingPlugin } from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";

import Toast, { POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";
import { useToast } from "vue-toastification";
import moment from "moment";

import store from "./store/index.js";

import modal from "@/components/Modal.vue";
import ArgonAlert from "@/components/ArgonAlert.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonSwitch from "@/components/ArgonSwitch.vue";
import BaseRadio from "@/components/BaseRadio.vue";
import Badge from "@/components/Badge.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import BaseDropdown from "@/components/BaseDropdown.vue";
import YearPicker from "@/components/YearPicker.vue";
import FileSelect from "@/components/FileSelect.vue";
import Card from "@/components/Card.vue";
import EmailLink from "@/components/EmailLink.vue";
import StatsCard from "@/components/StatsCard.vue";
import CardFromStats from "@/components/CardFromStats.vue";
import PageHeader from "@/components/PageHeader.vue";
import PageContent from "@/components/PageContent.vue";
import { ErrorMessage } from "./plugins/ErrorMessage.js";
import { vue3Debounce } from "vue-debounce";

import VueTippy from "vue-tippy";
import "tippy.js/dist/tippy.css";


const isProd = process.env.NODE_ENV === "production";

export default {
  install(app) {
    app.directive("debounce", vue3Debounce({ lock: true }));

    app.component("page-header", PageHeader);
    app.component("page-content", PageContent);
    app.use(VueTippy, {
      defaultProps: {
        placement: "bottom",
        animation: "fade",
        theme: "light",
        arrow: true,
      },
      directive: "Tooltip",
    });
    app.component("stats-card", StatsCard);
    app.component("email-link", EmailLink);
    app.component("card-from-stats", CardFromStats);
    app.component("card", Card);
    app.component("argon-button", ArgonButton);
    app.component("argon-input", ArgonInput);
    app.component("argon-switch", ArgonSwitch);
    app.component('base-radio', BaseRadio);
    app.component('badge', Badge);
    app.component('argon-alert', ArgonAlert);
    app.component('year-picker', YearPicker);
    app.component('file-select', FileSelect);
    app.component('base-dropdown', BaseDropdown);
    app.component('modal', modal);

    //app.use(moment); acho que não faz diferença
    app.use(LoadingPlugin, { color: store.state.auth.color, loader: "dots" });
    app.use(Toast, { position: POSITION.BOTTOM_RIGHT });
    app.use(globalDirectives);
    app.config.globalProperties.$toast = useToast();
    app.use(VueGoogleMaps, {
      load: {
        key: "YOUR_API_KEY_COMES_HERE",
      },
    });
    app.use(ErrorMessage);
    app.use(VueGtag, {
      config: {
        id: isProd ? "G-QF1C1LB250" : "G-MCGVFLSWTJ",
      },
    });
    app.mixin({
      methods: {
        formatDate(rawDate, format = "ll") {
          return moment(rawDate, "YYYY-MM-DD").locale("pt-br").format(format);
        },
        formatTimestamp(timestamp, format = "LLL") {
          return moment(timestamp, "YYYY-MM-DD hh:mm:ss")
            .locale("pt-br")
            .format(format);
        },
        getYear(input) {
          const date = this.moment(input);
          return date.isValid() ? date.year() : "...";
        },
        formatValue(value, allowNegative = false) {
          const valueNumber = Number(value);
          if (valueNumber > 0 || allowNegative) {
            return valueNumber.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            });
          }
          return "-";
        },
      },
    });
  },
};
