<template>
  <page-header class="min-vh-100">
    <div class="row justify-content-center mt-7">
      <div class="col-lg-5 col-md-7">
        <div
          class="card bg-white shadow border-0 vld-parent"
          ref="formContainer"
        >
          <img class="img mt-4 mb-4" :src="$store.state.sidenav.sidenavImage" />

          <div class="card-body px-lg-5 py-lg-2">
            <div class="text-center text-muted mb-4">
              <h4 class="fw-bolder">Redifinição de senha</h4>
              <small
                >Aqui você poderá redefinir a senha para a conta
                <b class="font-weight-bold">{{ $route.params.email }}</b></small
              >
            </div>
            <form role="form" @submit.prevent="handleSubmit">
              <argon-input
                size="lg"
                type="password"
                placeholder="Nova senha"
                addon-left-icon="ni ni-lock-circle-open"
                v-model="model.newPassword"
              >
              </argon-input
              ><argon-input
                size="lg"
                type="password"
                placeholder="Confirmar nova senha"
                addon-left-icon="ni ni-lock-circle-open"
                v-model="model.newPasswordConfirmation"
              >
              </argon-input>
              <p
                class="text-right pt-0 text-sm text-muted"
                v-show="
                  model.newPassword &&
                  model.newPasswordConfirmation &&
                  !newPasswordInputsMatch()
                "
              >
                <bdage class="text-danger">Senhas não conferem!</bdage>
              </p>
              <argon-alert type="danger" class="text-center" v-if="error"
                ><i class="fas fa-exclamation-circle mr-1"></i>
                <strong>Erro!</strong> {{ error }}</argon-alert
              >
              <div class="text-center">
                <argon-button
                  nativeType="submit"
                  color="success"
                  variant="gradient"
                  class="mt-1 mb-4 col-8 fs-4 px-0"
                  size="lg"
                  :disabled="!newPasswordInputsMatch()"
                  >Redefinir senha</argon-button
                >
              </div>
            </form>
            <div class="row mt-3">
              <div class="col-6 px-0 py-1 text-start card-footer px-lg-0 pt-1">
                <button class="px-1 button" @click="changeShowStatus">
                  <router-link class="nav-link px-0" to="/login">
                    <p class="mx-auto text-sm forgot-password">
                      Voltar para login
                    </p>
                  </router-link>
                </button>
              </div>
              <div class="col-6 px-0 py-1 text-end card-footer px-lg-0 pt-1">
                <button class="px-1 button" @click="changeShowStatus">
                  <router-link class="nav-link px-0" to="/forgot-password">
                    <p class="mx-auto text-sm forgot-password">
                      Solicitar novo email de redefinição
                    </p>
                  </router-link>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </page-header>
</template>
<script>
import api from '@/services/api';

export default {
  name: "forgot-password",
  data() {
    return {
      model: {
        newPassword: "",
        newPasswordConfirmation: "",
      },
      error: "",
    };
  },
  methods: {
    async handleSubmit() {
      this.error = "";
      let loader = this.$loading.show({
        container: this.$refs.formContainer,
        width: 128,
        height: 128,
      });
      try {
        await api.put(
          `/users/forgot-password/${this.$route.params.token}/${this.$route.params.email}`,
          this.model
        );
        this.$toast.success("Senha alterada com sucesso!");
        console.log('testando se deu certo');
        this.$router.push("/");
      } catch (error) {
        this.$error.errorMessageToast("Ocorreu um erro ao alterar a senha.", error);
      } finally {
        loader.hide();
      }
    },
    newPasswordInputsMatch() {
      if (this.model.newPassword && this.model.newPasswordConfirmation) {
        return this.model.newPassword === this.model.newPasswordConfirmation;
      }
      return false;
    },
  },
};
</script>
<style scoped>
.img {
  height: 100px;
  object-fit: contain;
}
</style>
