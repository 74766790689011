<template>
  <div class="container main-content">
    <div class="header mt-lg-5 mt-5">
      <div class="container">
        <div class="header-body text-center mb-3">
          <div class="row">
            <div class="col-12">
              <h1 class="text-white">Bem-vindo!</h1>
              <p class="text-lead text-white">
                Página de acesso ao Portal Hub de Inovação
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-12 mb-2">
        <p v-html="this.init_text" class="text-white text-center"></p>
        <span></span>
      </div>
      <div class="col-12 mb-4 vld-parent px-2 px-md-0" ref="stats_container">
        <div class="card-deck">
          <div class="row text-center">
            <div
              :class="`col-lg-6 d-flex align-items-stretch mt-4`"
              v-for="(card, index) in staticCards"
              :key="index"
            >
              <stats-card
                :title="card.name"
                Hover="gradient-success"
                type="success"
                :sub-title="stats[index].value || '-'"
                :iconInTop="true"
                :init_page="true"
                :icon="`fas ${card.icon} d-flex justify-content-center`"
                class="mb-4 mb-xl-0 flex-grow-1 cursor-pointer"
                @click="stats[index].open"
                v-if="stats[index].show()"
              >
              </stats-card>
            </div>
            <div
              class="col-lg-6 d-flex align-items-stretch mt-4 mb-4 mb-xl-0"
              v-for="(card, index) in dinamicCards"
              :key="index"
            >
              <stats-card
                :title="card.title"
                :margin="true"
                type="info"
                gradient="gradient-info"
                :openPopUp="card.open_pop_up"
                :contentButtonPopUp="card.open_pop_up"
                :contentCard="card.content"
                :iconInTop="true"
                :init_page="true"
                :icon="`fas fa-${card.icon} d-flex justify-content-center`"
                class="flex-grow-1"
                :class="[!card.open_pop_up ? 'scroll-content' : '']"
                @card-pop-up="openModalDinamicCard(card.card_id)"
              >
              </stats-card>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12" v-if="calls.length > 0">
        <hr class="my-4 text-white" />
      </div>
      <!-- Call area -->

      <div class="col-12 text-center" v-if="calls.length > 0">
        <h1 class="text-white">Chamadas em Aberto</h1>
        <p class="text-lead text-white">
          Você pode verificar as últimas chamadas abertas nos cards abaixo
        </p>
        <div class="col-12 mb-4 vld-parent px-2 px-md-0" ref="call_container">
          <div class="card-deck">
            <div class="row text-center">
              <div
                class="col-lg-4 d-flex align-items-stretch mt-4"
                v-for="(call, index) in calls"
                :key="index"
              >
                <stats-card
                  :title="call.name"
                  Hover="gradient-success"
                  :subTitle="` <div class='row'>
                      <p class='fw-bold m-0 fs-4 col-6 text-end'>Data Inicial: ${formatDate(
                        call.start_date
                      )}</p>
                      <p class='fw-bold m-0 fs-4 col-6 text-start'>Data Final: ${
                        call.end_date ? formatDate(call.end_date) : 'Contínuo'
                      }</p>
                    </div>
                    <div class='row'>
                      <p class='fw-bold m-0 fs-4 col-6 text-end'>Incubadora: ${
                        call.incubator_name
                      }</p>
                      <p class='fw-bold m-0 fs-4 col-6 text-start'>Tipo: ${
                        call.type
                      }</p>
                    </div>
                  `"
                  :iconInTop="true"
                  :init_page="true"
                  :margin="true"
                  :gradient="`${
                    index % 3 === 0
                      ? 'gradient-primary'
                      : index % 3 === 1
                      ? 'gradient-success'
                      : 'gradient-danger'
                  }`"
                  :icon="`ni ni-planet d-flex justify-content-center ms-1`"
                  class="mb-4 mb-xl-0 flex-grow-1 cursor-pointer"
                  v-tooltip="{
                    allowHTML: true,
                    content: `Abrir link para <strong>inscrição da chamada</strong>`,
                  }"
                  @click="openSubscription(call.slug)"
                >
                </stats-card>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--  -->
      <div class="col-12">
        <hr class="my-4 text-white" />
      </div>
      <div class="col-12" v-if="showMap">
        <p class="text-white text-center" style="font-size: 18, 4px">
          A incubadora está estruturada fisicamente por meio de unidades de
          incubação nos seus campi denominadas <b>Incubadoras Campus</b>,
          conforme mapa abaixo.
        </p>
      </div>
      <div class="col-12 vld-parent px-2 px-md-0" ref="map_container">
        <div style="height: 70vh" v-if="showMap">
          <l-map
            :center="map.center"
            :zoom="map.zoom"
            style="border-radius: 0.8rem"
          >
            <l-tile-layer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            ></l-tile-layer>
            <l-marker
              v-for="(incubadora, index) in incubadoras"
              :key="`marker_${index}`"
              :lat-lng="[incubadora.lat || '', incubadora.long || '']"
              @click="openModalViewIncubadora(incubadora)"
            >
              <l-icon
                :icon-url="icon.url"
                :options="icon.options"
                v-if="incubadora.lat && incubadora.long"
              ></l-icon>
            </l-marker>
          </l-map>
        </div>
      </div>
    </div>
  </div>
  <modal
    v-model:show="modals.incubadoras.show"
    modal-classes="modal-dialog-centered modal-xl"
    body-classes="pb-0"
    header-classes="pb-0"
  >
    <template #header>
      <h4 class="modal-title" id="modal-title-notification">Visualização</h4>
    </template>
    <div class="row">
      <div class="col-12 text-center">
        <h3 class="mb-2 modal-title">
          <i class="fas fa-university mr-1 text-success"></i> Incubadoras do
          Campus
          <span class="text-success">{{ domain_prefix }}</span>
        </h3>
      </div>
      <div
        class="col-lg-4 col-md-6 mt-5 d-flex align-items-stretch"
        v-for="(incubadora, index) in incubadoras"
        :key="`modal_inc_${index}`"
      >
        <div class="card text-center flex-grow-1">
          <div class="card-profile-image">
            <img
              :src="
                incubadora.profilePicture && incubadora.profilePicture.url
                  ? incubadora.profilePicture.url
                  : defaultLogoPath
              "
              class="card-image my-3 cursor-pointer cursor-hover"
              alt="Foto de perfil da incubadora"
              v-tooltip="{
                allowHTML: true,
                content: `Ver lista de <strong>Membros da Incubadora</strong>`,
              }"
              @click="openModalIncubadoraMembers(incubadora.user_id, 1)"
            />
          </div>
          <div class="card-body mt-8 pt-0 pb-3">
            <h2
              :class="[
                incubadora.nickname ? 'mb-0' : 'mb-2',
                'card-title text-orange',
              ]"
            >
              {{ incubadora.name }}
            </h2>
            <h4 v-if="incubadora.nickname">
              {{ incubadora.nickname }}
            </h4>
            <div>
              <div class="fs-5 fw-bold mb-1">
                <i class="ni ni-pin-3 me-1"> </i>{{ incubadora.city || "..." }},
                {{ incubadora.state || "..." }}
              </div>
            </div>
            <argon-button
              v-if="incubadora.website"
              type="link"
              class="p-2"
              :style="{
                background: $store.getters.colorGradient({
                  color: 'secondary',
                  gradient: -20,
                }),
              }"
              v-tooltip="'Abrir site da incubadora/campus'"
              @click="openLink(incubadora.website)"
              >Ver site <i class="fas fa-home ml-1"></i
            ></argon-button>
          </div>
        </div>
      </div>
    </div>
    <Pagination :meta="tableMeta" @load="fetchIncubadoras" />
    <template #footer>
      <argon-button
        type="link"
        color="gray-400"
        class="ml-auto shadow"
        @click="modals.incubadoras.show = false"
      >
        Voltar
      </argon-button>
    </template>
  </modal>
  <modal
    v-model:show="modals.members.show"
    modal-classes="modal-dialog-centered modal-xl"
    body-classes="pb-0"
    header-classes="pb-0"
    style="z-index: 2000"
  >
    <template #header>
      <h4 class="modal-title" id="modal-title-notification">Contatos</h4>
    </template>
    <div v-if="modals.members.members.length" class="row">
      <div class="col-12 mb-3 text-center">
        <h3 class="mb-2 modal-title">
          <i class="fas fa-user me-1 text-success"></i> Membros da
          {{ modals.members.organization_type }}
          <span class="text-success">{{
            modals.members.organization_name
          }}</span>
        </h3>
      </div>
      <div
        class="col-xl-6"
        v-for="(member, index) in modals.members.members"
        :key="`member_inc_${index}`"
      >
        <div class="card mb-4 text-left flex-grow-1">
          <div class="card-body mt-2 pt-0 pb-3">
            <div class="d-flex align-items-baseline">
              <h4 class="card-title text-uppercase text-muted mr-3">
                {{ member.name }}
              </h4>
              <!-- <small>{{ member.role }}</small> -->
            </div>
            <div class="row">
              <h3 class="font-weight-bold mb-0">
                <i class="fas fa-envelope m-1"></i>
                {{ member.user.email }}
              </h3>
              <div class="card-profile-image mr-3 mt-n4">
                <img
                  :src="
                    member.profilePicture && member.profilePicture.url
                      ? member.profilePicture.url
                      : defaultLogoPath
                  "
                  class="card-rounded-image"
                  alt="Foto do Membro"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="row">
      <div class="col-12 mb-3 text-center">
        <h3 class="mb-2 modal-title">
          <i class="fas fa-user me-1 text-success"></i> Esta
          {{ modals.members.organization_type }} não possui membros registrados.
        </h3>
      </div>
    </div>
    <template #footer>
      <argon-button
        type="link"
        color="gray-400"
        class="ml-auto shadow"
        @click="modals.members.show = false"
      >
        Voltar
      </argon-button>
    </template>
  </modal>
  <modal
    v-model:show="modals.incubadora.show"
    modal-classes="modal-dialog-centered modal-md"
    body-classes="pb-0"
    header-classes="pb-0"
  >
    <template #header>
      <h4 class="modal-title" id="modal-title-notification">Visualização</h4>
    </template>
    <div class="row">
      <div
        class="card text-center flex-grow-1 border-0"
        v-if="modals.incubadora.incubadora"
      >
        <div class="card-profile-image">
          <img
            :src="
              modals.incubadora.incubadora.profilePicture &&
              modals.incubadora.incubadora.profilePicture.url
                ? modals.incubadora.incubadora.profilePicture.url
                : defaultLogoPath
            "
            class="card-image my-3 cursor-pointer cursor-hover"
            alt="Foto de perfil da incubadora"
            v-tooltip="{
              allowHTML: true,
              content: `Ver lista de <strong>Membros da Incubadora</strong>`,
            }"
            @click="
              openModalIncubadoraMembers(
                modals.incubadora.incubadora.user_id,
                1
              )
            "
          />
        </div>
        <div class="card-body mt-8 pt-0 pb-0">
          <h2
            :class="[
              modals.incubadora.incubadora.nickname ? 'mb-0' : 'mb-2',
              'card-title text-orange',
            ]"
          >
            {{ modals.incubadora.incubadora.name }}
          </h2>
          <h4 v-if="modals.incubadora.incubadora.nickname">
            {{ modals.incubadora.incubadora.nickname }}
          </h4>
          <div>
            <div class="h5 font-weight-300 mb-1">
              <i class="ni ni-pin-3 mr-2"></i
              >{{ modals.incubadora.incubadora.city || "..." }},
              {{ modals.incubadora.incubadora.state || "..." }}
            </div>
          </div>
          <argon-button
            v-if="modals.incubadora.incubadora.website"
            type="link"
            class="p-2"
            :style="{
              background: $store.getters.colorGradient({
                color: 'secondary',
                gradient: -20,
              }),
            }"
            v-tooltip="'Abrir site da incubadora/campus'"
            @click="openLink(modals.incubadora.incubadora.website)"
            >Ver site <i class="fas fa-home ml-1"></i
          ></argon-button>
        </div>
      </div>
    </div>
    <template #footer>
      <argon-button
        type="link"
        color="gray-400"
        class="ml-auto shadow"
        @click="modals.incubadora.show = false"
      >
        Voltar
      </argon-button>
    </template>
  </modal>
  <modal
    v-model:show="modals.startups.show"
    modal-classes="modal-dialog-centered modal-xl"
    body-classes="pb-0"
    header-classes="pb-0"
  >
    <template #header>
      <h4 class="modal-title" id="modal-title-notification">Visualização</h4>
    </template>
    <div class="row">
      <div class="col-12 text-center">
        <h3 class="mb-2 modal-title">
          <i :class="[icons[modals.startups.type], 'mr-1 text-success']"></i>
          Empresas
          {{
            types[modals.startups.type]
              ? types[modals.startups.type].toLowerCase()
              : ""
          }}s
          <span class="text-success">de {{ domain_prefix }}</span>
        </h3>
      </div>
      <div
        class="col-lg-4 col-md-6 mt-5 d-flex align-items-stretch"
        v-for="(startup, index) in startups[modals.startups.type]"
        :key="`startup_${modals.startups.type}_${index}`"
      >
        <div class="card text-center flex-grow-1">
          <div class="card-profile-image">
            <img
              :src="
                startup.profilePicture && startup.profilePicture.url
                  ? startup.profilePicture.url
                  : defaultLogoPath
              "
              class="card-image my-3 cursor-pointer cursor-hover"
              alt="Logo da startup"
              v-tooltip="{
                allowHTML: true,
                content: `Ver lista de <strong>Membros da Startup</strong>`,
              }"
              @click="openModalStartupMembers(startup.userId)"
            />
          </div>
          <div class="card-body mt-8 pt-0 pb-3">
            <h2 class="card-title text-orange mb-1">
              {{ startup.name }}
            </h2>
            <p class="description mb-2" v-tooltip="'Incubadora/Campus'">
              <i class="fas fa-university m-1"></i>
              {{ startup.incubator.name }}
            </p>
            <div>
              <div class="fs-5 fw-300 mb-3" v-if="startup.sector">
                <badge
                  type="info"
                  v-tooltip="'Principal setor de atuação da startup'"
                  >{{ startup.sector }}</badge
                >
              </div>
              <div class="fs-5 fw-bold mb-1">
                <i class="ni ni-pin-3 me-1"></i>{{ startup.city || "..." }},
                {{ startup.state || "..." }}
              </div>
              <argon-button
                v-if="startup.website"
                type="link"
                class="p-2"
                :style="{
                  background: $store.getters.colorGradient({
                    color: 'secondary',
                    gradient: -20,
                  }),
                }"
                v-tooltip="'Abrir site da startup'"
                @click="openLink(startup.website)"
                >Ver site <i class="fas fa-home ml-1"></i
              ></argon-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <argon-button
        type="link"
        color="gray-400"
        class="ml-auto shadow"
        @click="modals.startups.show = false"
      >
        Voltar
      </argon-button>
    </template>
  </modal>
  <modal
    v-model:show="modals.visualization"
    modal-classes=" modal-dialog-centered"
  >
    <template #header>
      <h4 class="modal-title" id="modal-title-notification">
        {{ modalCard.pop_up_title }}
      </h4>
    </template>
    <div class="vld-parent" ref="modal_confirmation_delete">
      <div class="mt-n2">
        <div class="card-body" v-html="modalCard.content"></div>
      </div>
    </div>
    <template #footer>
      <argon-button
        type="link"
        color="gray-400"
        class="ml-auto shadow"
        @click="modals.visualization = false"
      >
        Voltar
      </argon-button>
    </template>
  </modal>
</template>

<script>
import api from "@/services/api.js";
import { LIcon, LMap, LTileLayer, LMarker } from "@vue-leaflet/vue-leaflet";
import "leaflet/dist/leaflet.css";
import Pagination from "@/components/Pagination.vue";

export default {
  name: "Home",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    Pagination,
  },
  data() {
    return {
      domain_prefix: window.location.hostname.split(".")[0],
      defaultLogoPath: "/img/pages/default_profile_picture.jpg",
      incubadoras: [],
      tableMeta: [],
      calls: [],
      dinamicCards: [],
      staticCards: [],
      modalCard: {},
      showMap: false,
      show_IncubadorasCampus: true,
      show_EmpresasPréIncubadas: true,
      show_EmpresasIncubadas: true,
      show_EmpresasGraduadas: true,
      show_MentoresnaRede: true,
      map: {
        center: [-14.235004, -51.92528],
        zoom: 4,
      },
      icon: {
        url: "/img/pages/red_pin.png",
        options: {
          iconSize: [36, 40],
          iconAnchor: [12, 41],
        },
      },
      init_text: "",
      stats: [
        {
          name: "total_incubadoras",
          value: "-",
          open: this.openModalViewIncubadoras,
          show: () => this.show_IncubadorasCampus,
        },
        {
          name: "total_pre_incubadas",
          value: "-",
          open: () => this.openModalViewStartups("pre_incubadas"),
          show: () => this.show_EmpresasPréIncubadas,
        },
        {
          name: "total_incubadas",
          value: "-",
          open: () => this.openModalViewStartups("incubadas"),
          show: () => this.show_EmpresasIncubadas,
        },
        {
          name: "total_graduadas",
          value: "-",
          open: () => this.openModalViewStartups("graduadas"),
          show: () => this.show_EmpresasGraduadas,
        },
        {
          name: "total_mentores",
          value: "",
          show: () => this.show_MentoresnaRede,
        },
      ],
      startups: {
        incubadas: [],
        pre_incubadas: [],
        graduadas: [],
      },
      types: {
        incubadas: "Incubada",
        pre_incubadas: "Pré-incubada",
        graduadas: "Graduada",
      },
      icons: {
        incubadas: "fas fa-rocket",
        pre_incubadas: "fas fa-paper-plane",
        graduadas: "fas fa-certificate",
      },
      modals: {
        incubadoras: {
          show: false,
        },
        incubadora: {
          show: false,
          incubadora: "",
        },
        startups: {
          show: false,
          type: "",
        },
        members: {
          show: false,
          organization_type: "",
          organization_name: "",
          members: {},
        },
        info: {
          show: false,
        },
        visualization: false,
      },
    };
  },
  beforeCreate() {
    const events = [
      "showMap",
      "init_text",
      "show_IncubadorasCampus",
      "show_EmpresasPréIncubadas",
      "show_EmpresasIncubadas",
      "show_EmpresasGraduadas",
      "show_MentoresnaRede",
    ];
    window.addEventListener("message", (event) => {
      if (event.data) {
        try {
          const data = JSON.parse(event.data);
          events.forEach((event) => {
            if (event in data) {
              this[event] = data[event];
            }
          });
        } catch (e) {
          // eslint-disable-line
        }
      }
    });
  },

  async mounted() {
    if(this.$store.state.sidenav.prefixUrl === "portalhubinovacao" && this.$route.params.id === "") this.$router.push('/login')
    console.log(this.$route.params.id)
    this.$route.params.id !== "" ? await this.fetchInfo(this.$route.params.id) : await this.fetchInfo();
  },
  methods: {
    async fetchInfo(rectoryId = undefined) {
      const loader = this.$loading.show({
        container: this.$refs.stats_container,
        width: 150,
        height: 150,
        opacity: 0,
      });
      try {
        let url = `public/public-stats?prefix=${this.domain_prefix}`;
        if (rectoryId) url += `&id=${rectoryId}`; 
        const { data: { stats, incubators, calls }  } = await api.get( url );
        console.log(stats)
        this.formatStats(stats);
        this.formatIncubators(incubators);
        this.formatCalls(calls);
        loader.hide();
      } catch (e) {
        console.error(e)
        this.$toast.error(
          "Não foi possível carregar as estatísticas no momento."
        );
      }
    },
    formatStats(data){
      this.stats.forEach((stat) => {
          if (Object.prototype.hasOwnProperty.call(data.stats, stat.name)) {
            stat.value = data.stats[stat.name];
          }
        });
        this.dinamicCards = data.dinamicCards;
        this.init_text = data.page[0].init_text;
        this.showMap = data.page[0].map;
        this.staticCards = data.StaticCards;
    },
    formatIncubators(data){
      this.incubadoras = data;
    },
    formatCalls(data){
      this.calls = data;
    },
    async openModalViewIncubadoras() {
      const stat = this.stats.find((stat) => stat.name === `total_incubadoras`);
      if (!stat || !stat.value) return;
      this.modals.incubadoras.show = true;
    },
    async openModalViewIncubadora(incubadora) {
      this.modals.incubadora.incubadora = incubadora;

      this.modals.incubadora.show = true;
    },
    async openModalIncubadoraMembers(incubator_id) {
      const loader = this.$loading.show({
        container: this.$refs.map_container,
        width: 150,
        height: 150,
        opacity: 0,
      });
      try {
        const { data } = await api.get(
          `public/public-stats/incubadora-members?incubadora_id=${incubator_id}`
        );
        this.modals.members.members = data;
        this.modals.members.show = true;
        this.modals.members.organization_type = "Incubadora";
        this.modals.members.organization_name = this.incubadoras.find(
          (incubadora) => incubadora.user_id === incubator_id
        ).name;
      } catch (e) {
        this.$toast.error("Não foi possível carregar as informações");
      } finally {
        loader.hide();
      }
    },
    async openModalViewStartups(type) {
      const stat = this.stats.find((stat) => stat.name === `total_${type}`);
      if (!stat || !stat.value) return;
      if (this.startups[type].length) {
        this.modals.startups.type = type;
        this.modals.startups.show = true;
        return;
      }
      const loader = this.$loading.show({
        container: this.$refs.stats_container,
        width: 150,
        height: 150,
        opacity: 0,
      });
      try {
        const { data } = await api.get(
          `public/public-stats/startups?type=${this.types[type]}&prefix=${this.domain_prefix}&id=${this.$route.params.id}`
        );
        this.startups[type] = data;
        this.modals.startups.type = type;
        this.modals.startups.show = true;
      } catch (e) {
        this.$toast.error("Não foi possível carregar as informações");
      } finally {
        loader.hide();
      }
    },
    async openModalStartupMembers(startup_id) {
      const loader = this.$loading.show({
        container: this.$refs.map_container,
        width: 150,
        height: 150,
        opacity: 0,
      });
      try {
        const { data } = await api.get(
          `public/public-stats/startup-members?startup_id=${startup_id}`
        );
        this.modals.members.members = data;
        this.modals.members.show = true;
        this.modals.members.organization_type = "Startup";
        let startupsArray = Object.values(this.startups).reduce(
          (accumulator, array) => accumulator.concat(array),
          []
        );
        this.modals.members.organization_name = startupsArray.find(
          (startup) => startup.userId === startup_id
        ).name;
      } catch (e) {
        this.$toast.error("Não foi possível carregar as informações");
      } finally {
        loader.hide();
      }
    },
    async openModalDinamicCard(id) {
      this.modalCard = this.dinamicCards.find((card) => card.card_id === id);
      this.modals.visualization = true;
    },

    openLink(link) {
      if (link) {
        const Window = window.open(link, "_blank", "noreferrer noopener");
        if (Window) {
          Window.focus();
        }
      }
    },
    openSubscription(slug) {
      const origin = window.location.origin;
      const subPath = {
        Incubação: "incubacao",
        "Pré-incubação": "pre-incubacao",
        Olimpíada: "olimpiada",
      };

      if (this.calls && this.calls.length > 0 && slug) {
        const call = this.calls.find((call) => call.slug === slug);
        if (call && call.type in subPath) {
          const link = `${origin}/chamadas/${
            subPath[call.type]
          }/${slug}/inscricao`;
          window.open(link, "_blank");
        } else {
          this.$toast.error(
            "Ocorreu um erro ao tentar abrir o link para inscrição."
          );
        }
      } else {
        console.error("Dados da chamada ausentes ou inválidos.");
      }
    },
    formatDate(date) {
      var data = new Date(date);
      var dia = data.getUTCDate().toString().padStart(2, "0");
      var mes = (data.getUTCMonth() + 1).toString().padStart(2, "0");
      var ano = data.getUTCFullYear();
      return dia + "-" + mes + "-" + ano;
    },
  },
  computed: {},
};
</script>
<style scoped>
.card-image {
  width: 145px;
  height: 145px;
  border-radius: 10%;
  margin: auto;
}
.card-rounded-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: auto;
  position: relative;
  display: flex;
  justify-content: center !important;
}
.scroll-content {
  max-height: 144.5px;
  overflow-y: auto;
}
</style>
