<template>
      <footer class="footer position-relative fs-4 py-4">
        <div class="row align-items-center justify-content-lg-between px-1">
          <div class="col-lg">
            <div class="copyright text-center text-lg-end text-muted">
              © {{ year }}
              Powered by
              <a
                href="https://starsaceleradora.com.br/"
                class="fw-bolder stars"
                target="_blank"
                >Stars Aceleradora</a
              >
            </div>
          </div>
        </div>
      </footer>
</template>
<script>
export default {
  props: {
    showSocial: {
      type: Boolean,
      default: true
    }
  },
  name: "content-footer",
  data() {
    return {
      year: new Date().getFullYear()
    };
  }
};
</script>
<style scoped>
.footer .nav-link {
  padding: 0 0.75rem;
}
.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 50px;
  padding: 0;
  line-height: 50px;
  background-color: transparent;
}
.stars {
  color: #fd7e14
}
.stars:hover {
  transition: 0.5s ease;
  color: #FF4200 
}
</style>
