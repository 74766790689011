<template>
    <div class="text-center mt-3" v-if="meta && meta.total > meta.per_page">
        <button class="button" v-if="meta.previous_page_url" @click="$emit('load', meta.current_page - 1)">
            <i class="fas  fa-angle-double-left text-gray"></i>
        </button>

        <template v-for="page in getVisiblePages()">
            <button v-if="isNumeric(page)" class="button" :key="page" @click="$emit('load', page)"
                :class="{ 'active': page === meta.current_page }">
                {{ page }}
            </button>
            <span v-else :key="`ellipsis-${page}`">{{ page }}</span>
        </template>

        <button class="button" v-if="meta.next_page_url" @click="$emit('load', meta.current_page + 1)">
            <i class="fas  fa-angle-double-right text-gray"></i>
        </button>
    </div>
</template>
<script>
export default {
    name: 'Pagination',
    emits: ['load'],
    props: {
        meta: {
            type: Object,
            required: true,
        },

    },
    data() {
        return {
        };
    },
    async mounted() {
    },
    methods: {

        isNumeric(page) {
            return !isNaN(parseFloat(page)) && isFinite(page);
        },


        getVisiblePages() {
            const currentPage = this.meta.current_page;
            const lastPage = this.meta.last_page;
            const visiblePages = [];

            if (lastPage <= 5) {
                for (let i = 1; i <= lastPage; i++) {
                    visiblePages.push(i);
                }
            } else {
                if (currentPage <= 3) {
                    for (let i = 1; i <= 5; i++) {
                        visiblePages.push(i);
                    }
                    visiblePages.push('...');
                    visiblePages.push(lastPage);
                } else if (currentPage >= lastPage - 2) {
                    visiblePages.push(1);
                    visiblePages.push('...');
                    for (let i = lastPage - 4; i <= lastPage; i++) {
                        visiblePages.push(i);
                    }
                } else {
                    visiblePages.push(1);
                    visiblePages.push('...');
                    for (let i = currentPage - 1; i <= currentPage + 1; i++) {
                        visiblePages.push(i);
                    }
                    visiblePages.push('...');
                    visiblePages.push(lastPage);
                }
            }

            return visiblePages;
        },
    },
};


</script>
<style scoped>
.button {
    display: inline-block;
    border-radius: 4px;
    background-color: white;
    border: none;
    color: black;
    font-size: 13px;
    transition: all 0.5s;
    cursor: pointer;
    margin: 3px;
}

.button span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
}

.button span:after {
    content: '\00bb';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -20px;
    transition: 0.5s;
}

.button.active {
    background-color: #ccc;
    /* Cor de fundo cinza para destacar a página ativa */
    color: #000;
    /* Cor do texto para a página ativa */
}

.button:hover span {
    padding-right: 25px;
}

.button:hover span:after {
    opacity: 1;
    right: 0;
}</style>