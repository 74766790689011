import {
  POP_UP_EXPIRE
} from "@/store/cacheConstants";

const getPopUpDateExpire = localStorage.getItem(POP_UP_EXPIRE) || undefined;
export const popup = {
  state: () => ({
    popUpDateExpire: getPopUpDateExpire,
  }),
  mutations: {
    storePopUpDateExpire(state) {
      const date = new Date();
      state.popUpDateExpire = date.setDate(date.getDate() + 1);
      localStorage.setItem(POP_UP_EXPIRE, date);
    },
    dropPopUpDateExpire(state) {
      state.popUpDateExpire = undefined;
      localStorage.removeItem(POP_UP_EXPIRE);
    },
   
  },
  getters: {
    
  },
  actions: {
    
  }
}