<template>
  <div class="form-check" :class="[inlineClass, { disabled: disabled }]">
    <input :id="cbId" class="form-check-input custom-control-input" type="radio" :name="nameId" :disabled="disabled"
      @click="$emit('update', value)" :value="name" />
    <label :for="cbId" class="custom-control-label">
      {{ name }}
    </label>
  </div>
</template>
<script>
import { randomString } from "./stringUtils";
export default {
  name: "base-radio",
  emits: ["update"],
  props: {
    value: {
      type: String,
      default: false
    },
    nameId: {
      type: String,
      description: "Radio name"
    },
    name: {
      type: [String, Number],
      description: "Radio label"
    },
    disabled: {
      type: Boolean,
      description: "Whether radio is disabled"
    },
    value: {
      type: [String, Boolean],
      description: "Radio value"
    },
    inline: {
      type: Boolean,
      description: "Whether radio is inline"
    }
  },
  data() {
    return {
      cbId: ""
    };
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    },
    inlineClass() {
      if (this.inline) {
        return `form-check-inline`;
      }
      return "";
    }
  },
  mounted() {
    this.cbId = randomString();
  }
};
</script>
<style scoped>
.form-check-inline {
  display: -webkit-inline-box !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
  margin-right: 3em;
}
</style>
