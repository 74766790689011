<template>
  <nav aria-label="breadcrumb">
    <ol class="px-0 pt-1 pb-0 mb-0 bg-transparent breadcrumb me-sm-6 text-white">
      <li class=" text-sm breadcrumb-item">
        <a class="opacity-8 text-white" href="#">Visão</a>
      </li>
      <li class="text-sm breadcrumb-item active text-white" aria-current="page">
        {{ $route.name ? $route.name.split("_")[0] : "" }}
      </li>
    </ol>
    <h4 class="mb-0 font-weight-bolder text-white">
      {{ currentPage ? currentPage.split("_")[1] : "" }}
    </h4>
    <!--Adicionado-->
    <!-- <div class="px-0 pt-1 pb-0 mb-0 bg-transparent breadcrumb me-sm-6">
      <li
        v-for="(crumb, index) in breadcrumbs"
        :key="index"
        class="text-sm breadcrumb-item text-white"
      >
        <router-link
          :to="crumb.to"
          :class="{ 'text-dark': showSidenav, 'text-white': !showSidenav }"
        >
          {{ crumb.label }}
        </router-link>
      </li>
    </div> -->
    <!---->
  </nav>
</template>

<script>
export default {
  name: "breadcrumbs",
  props: {
    currentPage: {
      required: true,
    },
    textWhite: {
      type: String,
    },
  },
  // computed: {
  //   breadcrumbs() {
  //     const routeMatched = this.$route.matched;
  //     const breadcrumbs = routeMatched.map((route) => ({
  //       to: route.path,
  //       label: route.meta.breadcrumb || route.meta.title || route.name
  //     }));
  //     return [].concat(...breadcrumbs);
  //   },
  // },
};
</script>
