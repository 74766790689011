import { createRouter, createWebHistory } from "vue-router";
import Tables from "../views/Tables.vue";
import Billing from "../views/Billing.vue";
import VirtualReality from "../views/VirtualReality.vue";
import RTL from "../views/Rtl.vue";
import Profile from "../views/Profile.vue";
import Home from "../views/Home.vue";
import Signin from "../views/Signin.vue";
import ForgotPassword from "../views/ForgotPassword";
import RecoverPassword from "../views/RecoverPassword";

import store from "@/store/index.js";
//  Laoyouts
import AuthLayout from "@/layout/AuthLayout";
//import SideNavbar from "@/examples/Sidenav/index.vue";
import BlankLayout from "@/layout/BlankLayout";
// import DashboardLayout from "../layout/admin/DashboardLayout.vue";
//import Navbar from "@/examples/Navbars/Navbar.vue";
const domain_prefix = window.location.hostname.split(".")[0];
const redirect = domain_prefix === 'portalhubinovacao' || domain_prefix === 'localhost' ? "/login" : "/inicio";

const routes = [
  {
    path: "",
    redirect: redirect,
  },
  {
    path: "/",
    redirect: redirect,
    component: AuthLayout,
    children: [
      {
        path: "inicio/:id?",
        name: "initial_page",
        component: Home,
      },
      {
        path: "login",
        name: "Login",
        component: Signin,
      },
      {
        path: "/forgot-password",
        name: "forgot_password",
        component: ForgotPassword,
      },
      {
        path: "/recover-password/:token/:email",
        name: "recover_password",
        component: RecoverPassword,
      },
    ],
  },
  //
  {
    path: "/mentor",
    component: BlankLayout,
    children: [
      {
        path: ":mentor_name/:mentor_id/excluir/:mentor_slug",
        name: "Excluir Mentor",
        meta: { title: "Exclusão de mentore" },
        component: () => import("../views/Mentor/Excluir.vue"),
      },
      {
        path: ":mentor_slug/chamada/:chamada_slug/avaliacao/:avaliacao_id",
        name: "Avaliação Mentor",
        meta: { title: "Chamada avaliação" },
        component: () =>
          import("../views/Mentor/Avaliacoes/AvaliacaoStartups.vue"),
      },
      {
        path:
          ":mentor_slug/chamada/:chamada_slug/avaliacao/:avaliacao_id/inscricao/:inscricao_id",
        name: "Avaliação Mentor Startup",
        meta: { title: "Chamada avaliação mentor startup" },
        component: () => import("../views/Mentor/Avaliacoes/AvaliacaoForm.vue"),
      },
      {
        path: ":mentor_slug/chamada/:chamada_slug/pitch-day/:pitch_day_id",
        name: "Pitch Day Mentor",
        meta: { title: "Chamada Pitch Day" },
        component: () =>
          import("../views/Mentor/PitchDay/PitchDayStartups.vue"),
      },
      {
        path:
          ":mentor_slug/chamada/:chamada_slug/pitch-day/:pitch_day_id/inscricao/:inscricao_id",
        name: "Pitch Day Mentor Startup",
        meta: { title: "Chamada Pitch Day Startup" },
        component: () => import("../views/Mentor/PitchDay/PitchDayForm.vue"),
      },
      {
        path:
          ":mentor_slug/chamada/:chamada_slug/due-diligence/:due_diligence_id",
        name: "Diligência Mentor",
        meta: { title: "Diligência Mentor" },
        component: () =>
          import("../views/Mentor/DueDiligence/DueDiligenceStartups.vue"),
      },
      {
        path:
          ":mentor_slug/chamada/:chamada_slug/due-diligence/:due_diligence_id/inscricao/:inscricao_id",
        name: "Diligência Mentor Startup",
        meta: { title: "Diligência Mentor Startup" },
        component: () =>
          import("../views/Mentor/DueDiligence/DueDiligenceView.vue"),
      },
    ],
  },
  // mentor mentoring confirmation
  {
    component: BlankLayout,
    children: [
      {
        path: "/mentorias/:token/confirmacao",
        name: "Mentorias_Confirmacao",
        meta: { title: "confirmação de mentoria" },
        component: () =>
          import("../views/Startup/Mentorias/MentoriaConfirmacao.vue"),
      },
      {
        path: "/mentorias/:token/avaliacao",
        name: "Mentorias_Avaliacao",
        meta: { title: "avaliação de mentoria" },
        component: () =>
          import("../views/Startup/Mentorias/MentoriaAvaliacao.vue"),
      },
    ],
  },
  {
    path: "/chamadas",
    component: BlankLayout,
    children: [
      {
        path: "incubacao/:slug/inscricao",
        name: "inscricao-incubacao",
        component: () =>
          import(
            "../views/Incubadora/Chamadas/FichaInscricao/IncubacaoForm.vue"
          ),
      },
      {
        path: "pre-incubacao/:slug/inscricao",
        name: "incricao-pre-incubacao",
        component: () =>
          import(
            "../views/Incubadora/Chamadas/FichaInscricao/PreIncubacaoForm.vue"
          ),
      },
      {
        path: "olimpiada/:slug/inscricao",
        name: "incricao-olimpiada",
        component: () =>
          import(
            "../views/Incubadora/Chamadas/FichaInscricao/OlimpiadaForm.vue"
          ),
      },
    ],
  },
   /* mirror iframe */
   {
    path: "/mirror",
    component: () => import("../components/MirrorIframe.vue"),
  },
  /*Admin routes*/
  {
    path: "/admin",
    redirect: "/admin/dashboard",
    beforeEnter(to, from, next) {
      if ((store.state.auth.roleMirror || store.state.auth.role) === "admin") {
        next();
      } else {
        next({ name: "Login" });
      }
    },
    component: () => import("../layout/admin/DashboardLayout.vue"),
    children: [
      {
        path: "dashboard",
        alias: "dashboard",
        name: "admin_Dashboard",
        meta: { title: "dashboard" },
        component: () => import("../views/Admin/Dashboard.vue"),
      },
      {
        path: "popups",
        name: "admin_popups",
        meta: { title: "Pop Ups" },
        component: () => import("../views/Admin/PopUps/PopUps.vue"),
      },
      {
        path: "popups/novo",
        name: "admin_popups_new",
        meta: { title: "Pop-Up Cadastro" },
        component: () => import("../views/Admin/PopUps/PopUpStore.vue"),
      },
      {
        path: "popups/:id/editar",
        name: "admin_popups_edit",
        meta: { title: "Pop-Up Edição" },
        component: () => import("../views/Admin/PopUps/PopUpEdit.vue"),
      },
      {
        path: "reitorias",
        alias: "reitoria",
        name: "admin_Reitorias",
        meta: { title: "reitorias" },
        component: () => import("../views/Admin/Reitorias.vue"),
      },
      {
        path: "reitorias/reitorias-tabela",
        name: "admin_Reitorias_Tabela",
        meta: { title: "reitorias em tabela" },
        component: () => import("../views/Admin/Reitoria/ReitoriasTable.vue"),
      },
      {
        path: "reitorias/nova",
        name: "admin_Reitorias_New",
        meta: { title: "cadastrar reitorias" },
        component: () => import("../views/Admin/Reitoria/ReitoriasStore.vue"),
      },
      {
        path: "reitorias/:id/editar",
        name: "admin_Reitorias_Edit",
        meta: { title: "editar reitorias" },
        component: () => import("../views/Admin/Reitoria/ReitoriasEdit.vue"),
      },
      {
        path: "reitorias/:id/acompanhamento",
        name: "admin_Reitorias_Acompanhamento",
        meta: { title: "Acompanhamento de reitorias" },
        component: () =>
          import("../views/Admin/Reitoria/ReitoriasAcompanhamento.vue"),
      },
      {
        path: "reitorias/:id/visualizacao-espelhada",
        name: "admin_Reitorias_Visu_espelhada",
        meta: { title: "Visulização espelhada da reitoria" },
        component: () => import("../views/Admin/Reitoria/ReitoriasMirror.vue"),
      },
      {
        path: "incubadoras",
        alias: "incubadora",
        name: "admin_Incubadoras",
        meta: { title: "listagem de incubadoras/campus" },
        component: () => import("../views/Admin/Incubadoras.vue"),
      },
      {
        path: "incubadoras/incubadoras-tabela",
        name: "admin_Incubadoras_Table",
        meta: { title: "tabela de incubadoras/campus" },
        component: () =>
          import("../views/Admin/Incubadoras/IncubadorasTable.vue"),
      },
      {
        path: "incubadoras/nova",
        name: "admin_Incubadoras_New",
        meta: { title: "cadastrar incubadora/campus" },
        component: () =>
          import("../views/Admin/Incubadoras/IncubadorasStore.vue"),
      },
      {
        path: "incubadoras/:id/editar",
        name: "admin_Incubadoras_Edit",
        meta: { title: "editar incubadora/campus" },
        component: () =>
          import("../views/Admin/Incubadoras/IncubadorasEdit.vue"),
      },
      {
        path: "incubadoras/:id/acompanhamento",
        name: "admin_Incubadoras_Acompanhamento",
        meta: { title: "Acompanhamento de incubadora/campus" },
        component: () =>
          import("../views/Admin/Incubadoras/IncubadorasAcompanhamento.vue"),
      },
      {
        path: "incubadoras/:id/visualizacao-espelhada",
        name: "admin_Incubadoras_Visu-espelhada",
        meta: { title: "Visulização espelhada de incubadora/campus" },
        component: () =>
          import("../views/Admin/Incubadoras/IncubadorasMirror.vue"),
      },
      {
        path: "startups",
        alias: "startup",
        name: "admin_Startups",
        meta: { title: "listagem de startups" },
        component: () => import("../views/Admin/Startups.vue"),
      },
      {
        path: "startups/startups-tabela",
        name: "admin_Tabela_Startups",
        meta: { title: "tabela de startups" },
        component: () => import("../views/Admin/Incubadoras/StartupsTable.vue"),
      },
      {
        path: "startups/nova",
        name: "admin_Startups_New",
        meta: { title: "cadastrar startups" },
        component: () => import("../views/Admin/Startups/StartupsStore.vue"),
      },
      {
        path: "startups/:id/editar",
        name: "admin_Startups_Edit",
        meta: { title: "editar estartups" },
        component: () => import("../views/Admin/Startups/StartupsEdit.vue"),
      },
      {
        path: "startups/:id/visualizacao-espelhada",
        name: "admin_Startups_Visu-espelhada",
        meta: { title: "Visulização espelhada de startup" },
        component: () =>
          import("../views/Admin/Startups/StartupsMirror.vue"),
      },
      {
        path: "membros",
        name: "admin_Membros",
        meta: { title: "listagem de membros" },
        component: () => import("../views/Admin/Membros/Membros.vue"),
      },
      {
        path: "membros/membros-tabela",
        name: "admin_Tabela_Membros",
        meta: { title: "tabela de membros" },
        component: () => import("../views/Admin/Membros/MembrosTable.vue"),
      },
      {
        path: "membros/:id/editar",
        name: "admin_Membros_Edit",
        meta: { title: "editar membros" },
        component: () => import("../views/Admin/Membros/MembroEdit.vue"),
      },
      {
        path: "membros/:id/visualizacao-espelhada",
        name: "admin_Membros_Visu-espelhada",
        meta: { title: "Visulização espelhada de startup" },
        component: () =>
          import("../views/Admin/Membros/MembroMirror.vue"),
      },
      {
        path: "convidados",
        name: "admin_Convidados",
        meta: { title: "listagem de convidados" },
        component: () => import("../views/Admin/Convidados/Convidados.vue"),
      },
      {
        path: "convidados/convidados-tabela",
        name: "admin_Tabela_Convidados",
        meta: { title: "tabela de convidados" },
        component: () => import("../views/Admin/Convidados/ConvidadosTable.vue"),
      },
      {
        path: "convidados/:id/editar",
        name: "admin_Convidados_Edit",
        meta: { title: "editar convidados" },
        component: () => import("../views/Admin/Convidados/ConvidadoEdit.vue"),
      },
      {
        path: "convidados/:id/visualizacao-espelhada",
        name: "admin_Convidados_Visu-espelhada",
        meta: { title: "Visulização espelhada de convidado" },
        component: () =>
          import("../views/Admin/Convidados/ConvidadoMirror.vue"),
      },
      {
        path: "trilha",
        alias: "trilhas",
        name: "admin_Trilha",
        meta: { title: "trilha de qualificação" },
        component: () => import("../views/Admin/Trilha.vue"),
      },
      {
        path: "trilha/cursos/novo",
        name: "admin_curso_novo",
        meta: { title: "cadastrar curso" },
        component: () => import("../views/Admin/Trilha/CourseRegister.vue"),
      },
      {
        path: "trilha/cursos/:id/editar",
        name: "admin_cursos_edit",
        meta: { title: "trilha de qualificação - editar curso" },
        component: () => import("../views/Admin/Trilha/CourseEdit.vue"),
      },
      {
        path: "trilha/cursos/:id/ranking",
        name: "admin_cursos_ranking",
        meta: { title: "trilha de qualificação - ranking de acesso" },
        component: () => import("../views/Admin/Trilha/Ranking.vue"),
      },
      {
        path: "trilha/cursos/:id/secoes",
        name: "admin_cursos_secoes",
        meta: {
          title: "trilha de qualificação - gerenciar seções do curso",
        },
        component: () => import("../views/Admin/Trilha/Sections.vue"),
      },
      {
        path: "trilha/cursos/:id/materiais/",
        name: "admin_cursos_materials",
        meta: { title: "trilha de qualificação - materiais do curso" },
        component: () => import("../views/Admin/Trilha/Materials.vue"),
      },

      {
        path: "configuracoes",
        alias: "configuracao",
        name: "admin_Configurações",
        meta: { title: "configurações" },
        component: () => import("../views/Admin/Configuracoes.vue"),
      },
      /*{
        path: "suporte",
        name: "admin_Suporte",
        meta: { title: "suporte" },
        component: () => import("../views/Admin/Suporte.vue")
      },
      */
    ],
  },
  /*Reitoria routes*/
  {
    path: "/reitoria",
    redirect: "/reitoria/dashboard",
    beforeEnter(to, from, next) {
      if ((store.state.auth.roleMirror || store.state.auth.role)  === "rectory") {
        next();
      } else {
        next({ name: "Login" });
      }
    },
    component: () => import("../layout/reitoria/DashboardLayout.vue"),
    children: [
      {
        path: "/reitoria",
        alias: "dashboard",
        name: "reitoria_Dashboard",
        meta: { title: "dashboard" },
        component: () => import("../views/Reitoria/Dashboard.vue"),
      },
      {
        path: "incubadoras",
        alias: "incubadora",
        name: "reitoria_Incubadoras",
        meta: { title: "incubadoras" },
        component: () => import("../views/Reitoria/Incubadoras.vue"),
      },
      {
        path: "incubadoras/incubadoras-tabela",
        name: "reitoria_Incubadoras_Table",
        meta: { title: "tabela de incubadoras/campus" },
        component: () => import("../views/Reitoria/IncubadorasTable.vue"),
      },
      {
        path: "incubadoras/nova",
        alias: "incubadora",
        name: "reitoria_Incubadoras_New",
        meta: { title: "cadastrar incubadoras" },
        component: () =>
          import("../views/Reitoria/Incubadoras/IncubadorasStore.vue"),
      },
      {
        path: "incubadoras/:id/editar",
        name: "reitoria_Incubadoras_Edit",
        meta: { title: "editar incubadoras" },
        component: () =>
          import("../views/Reitoria/Incubadoras/IncubadorasEdit.vue"),
      },
      {
        path: "incubadoras/:id/acompanhamento",
        name: "reitoria_Incubadoras_Acompanhamento",
        meta: { title: "Acompanhamento de incubadoras/campus" },
        component: () =>
          import("../views/Reitoria/Incubadoras/IncubadorasAcompanhamento.vue"),
      },
      {
        path: "incubadoras/:id/visualizacao-espelhada",
        name: "reitoria_Incubadoras_Visu-espelhada",
        meta: { title: "Visualização espelhada da incubadora/campus" },
        component: () =>
          import("../views/Reitoria/Incubadoras/IncubadorasMirror.vue"),
      },
      {
        path: "startups/:id/visualizacao-espelhada",
        name: "reitoria_Startups_Visu-espelhada",
        meta: { title: "Visualização espelhada da startup" },
        component: () =>
          import("../views/Reitoria/Startups/StartupsMirror.vue"),
      },
      
      {
        path: "convidados",
        name: "reitoria_Convidados",
        meta: { title: "convidados da reitoria" },
        component: () => import("../views/Reitoria/Convidados.vue"),
      },
      {
        path: "convidados/convidados-tabela",
        name: "reitoria_Tabela de Convidados",
        meta: { title: "tabela de convidados" },
        component: () =>
          import("../views/Reitoria/Convidados/ConvidadosTable.vue"),
      },
      {
        path: "convidados/novo",
        name: "reitoria_Novo Convidado",
        meta: { title: "cadastrar convidado" },
        component: () =>
          import("../views/Reitoria/Convidados/ConvidadoStore.vue"),
      },
      {
        path: "convidados/:id/editar",
        name: "reitoria_Editar Convidado",
        meta: { title: "editar convidado" },
        component: () =>
          import("../views/Reitoria/Convidados/ConvidadoEdit.vue"),
      },
      {
        path: "convidados/:id/visualizacao-espelhada",
        name: "reitoria_Convidados_Visu-espelhada",
        meta: { title: "Visualização espelhada da startup" },
        component: () =>
          import("../views/Reitoria/Convidados/ConvidadoMirror.vue"),
      },
      {
        path: "planejamentos",
        name: "reitoria_Planejamento",
        meta: { title: "Atividades" },
        component: () => import("../views/Reitoria/Planejamento.vue"),
      },
      {
        path: "planejamentos/unidades-metricas",
        name: "reitoria_unidades",
        meta: { title: "unidades" },
        component: () =>
          import("../views/Reitoria/Planejamento/UnidadesMetricas.vue"),
      },
      {
        path: "planejamentos/indicadores-metricas",
        name: "reitoria_indicadores",
        meta: { title: "indicadores" },
        component: () =>
          import("../views/Reitoria/Planejamento/IndicadoresMetricas.vue"),
      },
      {
        path: "planejamentos/dashboard-metricas",
        name: "reitoria_dashboard_metricas",
        meta: { title: "dashboard" },
        component: () =>
          import("../views/Reitoria/Planejamento/DashboardMetricas.vue"),
      },
      {
        path: "cernes",
        alias: "cerne",
        name: "reitoria_Planejamento_Cerne",
        meta: { title: "Cerne" },
        component: () => import("../views/Reitoria/Cerne.vue"),
      },
      {
        path: "cerne/:idCerne/processos",
        name: "reitoria_cerne_processos",
        meta: { title: "cerne - processos" },
        component: () => import("../views/Reitoria/Cerne/Processos.vue"),
      },
      {
        path: "cerne/:idCerne/processos/:numberProcesso/praticas",
        name: "reitoria_cerne_praticas",
        meta: { title: "cerne - práticas do processo" },
        component: () => import("../views/Reitoria/Cerne/Praticas.vue"),
      },
      {
        path:
          "cerne/:idCerne/processos/:numberProcesso/praticas/:idPratica/evidencias/:idTarefa",
        name: "reitoria_cerne_evidencias",
        meta: { title: "cerne - evidências" },
        component: () => import("../views/Reitoria/Cerne/Evidencias.vue"),
      },
      {
        path:
          "cerne/:idCerne/processos/:numberProcesso/praticas/:idPratica/nova",
        name: "reitoria_cerne_nova_pratica",
        meta: { title: "cerne - nova prática-chave" },
        component: () => import("../views/Reitoria/Cerne/NovaPratica.vue"),
      },
      {
        path:
          "cerne/:idCerne/processos/:numberProcesso/praticas/:idPratica/tarefa/:idTarefa/editar",
        name: "reitoria_cerne_editar_pratica",
        meta: { title: "cerne - editar prática-chave" },
        component: () => import("../views/Reitoria/Cerne/EditarPratica.vue"),
      },
      {
        path: "startups-tabela",
        alias: "startups",
        name: "reitoria_Startups",
        meta: { title: "startups" },
        component: () => import("../views/Reitoria/Startups/StartupsTable.vue"),
      },
      {
        path: "startups/nova",
        name: "reitoria_Startups_New",
        meta: { title: "cadastrar startups" },
        component: () => import("../views/Reitoria/Startups/StartupsStore.vue"),
      },
      {
        path: "startups/:id/editar",
        name: "reitoria_Editar Startup",
        meta: { title: "editar startup" },
        component: () => import("../views/Reitoria/Startups/StartupEdit.vue"),
      },
      {
        path: "equipe",
        name: "reitoria_Equipe da Reitoria",
        meta: { title: "equipe da reitoria" },
        component: () => import("../views/Reitoria/Membros.vue"),
      },
      {
        path: "equipe/equipe-tabela",
        name: "reitoria_Tabela de Membros",
        meta: { title: "tabela de membros" },
        component: () => import("../views/Reitoria/Members/MembrosTable.vue"),
      },
      {
        path: "equipe/novo",
        name: "reitoria_Novo Membro",
        meta: { title: "cadastrar membro" },
        component: () => import("../views/Reitoria/Members/MembroStore.vue"),
      },
      {
        path: "equipe/:id/editar",
        name: "reitoria_Editar Membro",
        meta: { title: "editar membro" },
        component: () => import("../views/Reitoria/Members/MembroEdit.vue"),
      },
      {
        path: "mentores",
        alias: "mentor",
        name: "reitoria_Mentores",
        meta: { title: "listagem de mentores compartilhados" },
        component: () => import("../views/Reitoria/Mentores.vue"),
      },
      {
        path: "mentores-tabela",
        name: "reitoria_Mentores_Table",
        meta: { title: "tabela de mentores" },
        component: () => import("../views/Reitoria/Mentores/MentoresTable.vue"),
      },
      {
        path: "mentores/novo",
        name: "reitoria_Mentores_New",
        meta: { title: "cadastrar mentores" },
        component: () => import("../views/Reitoria/Mentores/MentoresStore.vue"),
      },
      {
        path: "mentores/:id/editar",
        name: "reitoria_Mentores_Edit",
        meta: { title: "editar mentores" },
        component: () => import("../views/Reitoria/Mentores/MentoresEdit.vue"),
      },
      {
        path: "suporte",
        alias: "suporte",
        name: "reitoria_Suporte",
        meta: { title: "suporte" },
        component: () => import("../views/Reitoria/Suporte.vue"),
      },
      {
        path: "noticias",
        alias: "noticia",
        name: "reitoria_Notícias",
        meta: { title: "notícias públicas" },
        component: () => import("../views/Reitoria/Noticias.vue"),
      },
      {
        path: "comunidade",
        alias: "comunidades",
        name: "reitoria_Comunidade",
        meta: { title: "comunidade" },
        component: () => import("../views/Reitoria/Comunidade.vue"),
      },
      {
        path: "trilha",
        alias: "trilhas",
        name: "reitoria_Trilha",
        meta: { title: "trilha de qualificação" },
        component: () => import("../views/Reitoria/Trilha.vue"),
      },
      {
        path: "trilha/cursos/novo",
        name: "reitoria_curso_novo",
        meta: { title: "cadastrar curso" },
        component: () => import("../views/Reitoria/Trilha/CourseRegister.vue"),
      },
      {
        path: "trilha/cursos/:id/editar",
        name: "reitoria_cursos_edit",
        meta: { title: "trilha de qualificação - editar curso" },
        component: () => import("../views/Reitoria/Trilha/CourseEdit.vue"),
      },
      {
        path: "trilha/cursos/:id/ranking",
        name: "reitoria_cursos_ranking",
        meta: { title: "trilha de qualificação - ranking de acesso" },
        component: () => import("../views/Reitoria/Trilha/Ranking.vue"),
      },
      {
        path: "trilha/cursos/:id/secoes",
        name: "reitoria_cursos_secoes",
        meta: {
          title: "trilha de qualificação - gerenciar seções do curso",
        },
        component: () => import("../views/Reitoria/Trilha/Sections.vue"),
      },
      {
        path: "trilha/cursos/:id/materiais/",
        name: "reitoria_cursos_materials",
        meta: { title: "trilha de qualificação - materiais do curso" },
        component: () => import("../views/Reitoria/Trilha/Materials.vue"),
      },
      {
        path: "pagina-inicial",
        name: "reitoria_Página inicial",
        meta: { title: "Pagina inicial" },
        component: () => import("../views/Reitoria/PaginaInicial.vue"),
      },
      {
        path: "configuracoes",
        name: "reitoria_Configurações",
        meta: { title: "configurações" },
        component: () => import("../views/Reitoria/Configuracoes.vue"),
      },
      {
        path: "reitorias/:id/visualizar",
        name: "test",
        meta: { title: "teste de visualização" },
        component: () => import("../views/Reitoria/Configuracoes.vue"),
      },
      {
        path: "relatorios",
        name: "reitoria_Relatórios",
        meta: { title: "Relatórios" },
        component: () => import("../views/Reitoria/Relatorios.vue"),
      },

      /*{
        path: "suporte",
        name: "reitoria_Suporte",
        meta: { title: "suporte" },
        component: () => import("../views/Reitoria/Suporte.vue")
      },*/
    ],
  },
  /*Incubadora routes*/
  {
    path: "/incubadora",
    redirect: "/incubadora/dashboard",
    beforeEnter(to, from, next) {
      if ((store.state.auth.roleMirror || store.state.auth.role)  === "incubator") {
        next();
      } else {
        next({ name: "Login" });
      }
    },
    component: () => import("../layout/incubadora/DashboardLayout.vue"),
    children: [
      {
        path: "/incubadora",
        alias: "dashboard",
        name: "incubadora_Dashboard",
        meta: { title: "dashboard" },
        component: () => import("../views/Incubadora/Dashboard.vue"),
      },
      {
        path: "planejamentos/tipos-metricas",
        name: "incubadora_planejamentos_tipos",
        meta: { title: "tipos de métricas" },
        component: () =>
          import("../views/Incubadora/Planejamentos/TiposMetricas.vue"),
      },
      {
        path: "planejamentos",
        name: "incubadora_planejamento",
        meta: { title: "planejamentos" },
        component: () => import("../views/Incubadora/Planejamento.vue"),
      },
      {
        path: "recursos",
        name: "incubadora_recursos",
        meta: { title: "gestão de recursos" },
        component: () => import("../views/Incubadora/Recursos.vue"),
      },
      {
        path: "recursos/lançamentos/novo",
        name: "incubadora_lancamento_new",
        meta: { title: "novo lançamento" },
        component: () =>
          import("../views/Incubadora/Recursos/LancamentoForm.vue"),
      },
      {
        path: "recursos/lançamentos/:id/editar",
        name: "incubadora_lancamento_edit",
        meta: { title: "editar lançamento" },
        component: () =>
          import("../views/Incubadora/Recursos/LancamentoEdit.vue"),
      },
      {
        path: "cerne",
        alias: "cernes",
        name: "incubadora_Cerne",
        meta: { title: "cerne" },
        component: () => import("../views/Incubadora/Cerne.vue"),
      },
      {
        path: "cerne/:idCerne/processos",
        name: "incubadora_cerne_processos",
        meta: { title: "cerne - processos" },
        component: () => import("../views/Incubadora/Cerne/Processos.vue"),
      },
      {
        path: "cerne/:idCerne/processos/:numberProcesso/praticas",
        name: "incubadora_cerne_praticas",
        meta: { title: "cerne - práticas do processo" },
        component: () => import("../views/Incubadora/Cerne/Praticas.vue"),
      },
      {
        path:
          "cerne/:idCerne/processos/:numberProcesso/praticas/:idPratica/evidencias/:idTarefa",
        name: "incubadora_cerne_evidencias",
        meta: { title: "cerne - evidências" },
        component: () => import("../views/Incubadora/Cerne/Evidencias.vue"),
      },
      {
        path:
          "cerne/:idCerne/processos/:numberProcesso/praticas/:idPratica/nova",
        name: "incubadora_cerne_nova_pratica",
        meta: { title: "cerne - nova prática-chave" },
        component: () => import("../views/Incubadora/Cerne/NovaPratica.vue"),
      },
      {
        path:
          "cerne/:idCerne/processos/:numberProcesso/praticas/:idPratica/tarefa/:idTarefa/editar",
        name: "incubadora_cerne_editar_pratica",
        meta: { title: "cerne - editar prática-chave" },
        component: () => import("../views/Incubadora/Cerne/EditarPratica.vue"),
      },
      {
        path: "chamadas",
        name: "incubadora_chamadas",
        meta: { title: "chamadas para startups" },
        component: () => import("../views/Incubadora/Chamadas/Chamadas.vue"),
      },
      {
        path: "chamadas/nova",
        name: "incubadora_chamadas_new",
        meta: { title: "nova chamada para startups" },
        component: () =>
          import("../views/Incubadora/Chamadas/ChamadasForm.vue"),
      },
      {
        path: "chamadas/:id/editar",
        name: "incubadora_chamadas_edit",
        meta: { title: "editar chamada para startups" },
        component: () =>
          import("../views/Incubadora/Chamadas/ChamadasEdit.vue"),
      },
      {
        path: "chamadas/:id/inscricoes",
        name: "incubadora_chamadas_inscricoes",
        meta: { title: "lista de inscrições na chamada" },
        component: () =>
          import("../views/Incubadora/Chamadas/Inscricoes/Inscricoes.vue"),
      },
      {
        path: "chamadas/:id/inscricoes-tabela",
        name: "incubadora_chamadas_inscricoes-table",
        meta: { title: "tabela inscrições na chamada" },
        component: () =>
          import("../views/Incubadora/Chamadas/Inscricoes/InscricoesTable.vue"),
      },
      {
        path: "chamadas/:idCall/inscricoes/incubacao/:idSubscription",
        name: "incubadora_chamadas_inc_inscricao_view",
        meta: { title: "gerenciar inscrição" },
        component: () =>
          import(
            "../views/Incubadora/Chamadas/Inscricoes/InscricaoIncubacaoView.vue"
          ),
      },
      {
        path: "chamadas/:idCall/inscricoes/pre-incubacao/:idSubscription",
        name: "incubadora_chamadas_pre_inscricao_view",
        meta: { title: "gerenciar inscrição" },
        component: () =>
          import(
            "../views/Incubadora/Chamadas/Inscricoes/InscricaoPreIncubacaoView.vue"
          ),
      },
      {
        path: "chamadas/:idCall/inscricoes/olimpiada/:idSubscription",
        name: "incubadora_chamadas_olimpiada_view",
        meta: { title: "gerenciar inscrição" },
        component: () =>
          import(
            "../views/Incubadora/Chamadas/Inscricoes/InscricaoOlimpiadaView.vue"
          ),
      },
      {
        path: "chamadas/:id/avaliacao",
        name: "incubadora_chamadas_avaliacao",
        meta: { title: "gerenciar avaliação das startups" },
        component: () =>
          import("../views/Incubadora/Chamadas/Avaliacoes/AvaliacaoForm.vue"),
      },
      {
        path: "chamadas/:idCall/avaliacoes/:idEvaluation/respostas",
        name: "incubadora_avaliacao_repostas",
        meta: { title: "avaliações dos mentores" },
        component: () =>
          import(
            "../views/Incubadora/Chamadas/Avaliacoes/AvaliacaoAnswers.vue"
          ),
      },
      {
        path: "chamadas/:id/pitch-day",
        name: "incubadora_pitch_day",
        meta: { title: "gerenciar pitch day" },
        component: () =>
          import("../views/Incubadora/Chamadas/PitchDays/PitchDayForm.vue"),
      },
      {
        path: "chamadas/:idCall/pitch-days/:idPitchDay/respostas",
        name: "incubadora_pitch_day_repostas",
        meta: { title: "pitch day - avaliações dos mentores" },
        component: () =>
          import("../views/Incubadora/Chamadas/PitchDays/PitchDayAnswers.vue"),
      },
      {
        path: "chamadas/:id/due-diligence",
        name: "incubadora_due_diligence",
        meta: { title: "gerenciar due diligence" },
        component: () =>
          import(
            "../views/Incubadora/Chamadas/DueDiligences/DueDiligenceForm.vue"
          ),
      },
      {
        path: "chamadas/:id/aprovar",
        name: "incubadora_aprovar",
        meta: { title: "gerenciar aprovações" },
        component: () =>
          import("../views/Incubadora/Chamadas/Aprovar/AprovarForm.vue"),
      },
      {
        path: "batches",
        alias: "batch",
        name: "incubadora_Batches",
        meta: { title: "batches de startups" },
        component: () => import("../views/Incubadora/Batches/Batches.vue"),
      },
      {
        path: "batches/conjuntos",
        name: "incubadora_batches_task_set",
        meta: { title: "batches - gerenciar conjunto de tarefas" },
        component: () =>
          import("../views/Incubadora/Batches/TaskSet/TaskSet.vue"),
      },
      {
        path: "batches/conjuntos/novo",
        name: "incubadora_batches_task_set_new",
        meta: { title: "gerenciar conjunto de tarefas - novo" },
        component: () =>
          import("../views/Incubadora/Batches/TaskSet/TaskSetForm.vue"),
      },
      {
        path: "batches/conjuntos/:id/editar",
        name: "incubadora_batches_task_set_edit",
        meta: { title: " gerenciar conjunto de tarefas - editar" },
        component: () =>
          import("../views/Incubadora/Batches/TaskSet/TaskSetEdit.vue"),
      },
      {
        path: "batches/conjuntos/:id/tarefas/:tarefa",
        name: "incubadora_batches_task_set_task",
        meta: { title: "gerenciar conjunto de tarefas - tarefa" },
        component: () =>
          import("../views/Incubadora/Batches/TaskSet/TaskEdit.vue"),
      },
      {
        path: "batches/novo",
        name: "incubadora_batches_new",
        meta: { title: "novo batch" },
        component: () => import("../views/Incubadora/Batches/BatchesForm.vue"),
      },
      {
        path: "batches/:id/editar",
        name: "incubadora_batches_edit",
        meta: { title: "editar batches de startups" },
        component: () => import("../views/Incubadora/Batches/BatchesEdit.vue"),
      },
      {
        path: "batches/:id/acompanhamento",
        name: "incubadora_batches_acompanhamento",
        meta: { title: "acompanhamento das startups" },
        component: () => import("../views/Incubadora/Batches/Tasks/Tasks.vue"),
      },
      {
        path: "batches/:idBatch/acompanhamento/tarefas/novo",
        name: "incubadora_batches_task_new",
        meta: { title: "acompanhamento das startups - nova tarefa" },
        component: () =>
          import("../views/Incubadora/Batches/Tasks/TasksForm.vue"),
      },
      {
        path: "batches/:idBatch/acompanhamento/tarefas/:idTask/editar",
        name: "incubadora_batches_task_edit",
        meta: { title: "acompanhamento das startups - tarefa" },
        component: () =>
          import("../views/Incubadora/Batches/Tasks/TasksEdit.vue"),
      },
      {
        path: "batches/:idBatch/acompanhamento/startups/:idStartup/respostas",
        name: "incubadora_batches_startup_tasks",
        meta: { title: "acompanhamento das startups" },
        component: () =>
          import("../views/Incubadora/Batches/Tasks/TaskStartup.vue"),
      },
      {
        path: "batches/:idBatch/acompanhamento/startups/:idStartup/metricas",
        name: "incubadora_batches_startup_report",
        meta: { title: "report mensal da startup" },
        component: () =>
          import("../views/Incubadora/Batches/Metrics/StartupMetrics.vue"),
      },
      {
        path: "startups",
        alias: "startup",
        name: "incubadora_Startups",
        meta: { title: "listagem de startups" },
        component: () => import("../views/Incubadora/Startups.vue"),
      },
      {
        path: "startups/startups-tabela",
        name: "incubadora_Tabela de Startups",
        meta: { title: "tabela de startups" },
        component: () =>
          import("../views/Incubadora/Startups/StartupsTable.vue"),
      },
      {
        path: "startups/nova",
        name: "incubadora_Nova Startup",
        meta: { title: "cadastrar startup" },
        component: () =>
          import("../views/Incubadora/Startups/StartupsStore.vue"),
      },
      {
        path: "startups/:id/editar",
        name: "incubadora_Editar Startup",
        meta: { title: "editar startup" },
        component: () =>
          import("../views/Incubadora/Startups/StartupsEdit.vue"),
      },
      {
        
        path: "startups/:id/visualizacao-espelhada",
        name: "incubadora_Startups_Visu-espelhada",
        meta: { title: "Visualização espelhada da startup" },
        component: () =>
          import("../views/Incubadora/Startups/StartupsMirror.vue"),
      },
      {
        path: "startups/:id/equipe",
        name: "incubadora_Equipe da Startup",
        meta: { title: "equipe da startup" },
        component: () =>
          import("../views/Incubadora/Startups/StartupsMembros.vue"),
      },
      {
        path: "startups/:id/mentorias",
        name: "incubadora_Mentorias da Startup",
        meta: { title: "mentorias da startup" },
        component: () =>
          import("../views/Incubadora/Startups/StartupsMentorias.vue"),
      },
      {
        path: "startups/:id/batches",
        name: "incubadora_Batches da Startup",
        meta: { title: "batches da startup" },
        component: () =>
          import("../views/Incubadora/Startups/StartupsBatches.vue"),
      },
      {
        path: "startups/:id/mentorias",
        name: "incubadora_Mentorias da Startup",
        meta: { title: "mentorias da startup" },
        component: () =>
          import("../views/Incubadora/Startups/StartupsMentorias.vue"),
      },
      {
        path: "startups/:id/batches",
        name: "incubadora_Batches da Startup",
        meta: { title: "batches da startup" },
        component: () =>
          import("../views/Incubadora/Startups/StartupsBatches.vue"),
      },
      {
        path: "mentores",
        alias: "mentor",
        name: "incubadora_Mentores",
        meta: { title: "listagem de mentores compartilhados" },
        component: () => import("../views/Incubadora/Mentores.vue"),
      },
      {
        path: "mentores-tabela",
        name: "incubadora_Mentores_Table",
        meta: { title: "tabela de mentores" },
        component: () =>
          import("../views/Incubadora/Mentores/MentoresTable.vue"),
      },
      {
        path: "mentores/novo",
        name: "incubadora_Mentores_New",
        meta: { title: "cadastrar mentores" },
        component: () =>
          import("../views/Incubadora/Mentores/MentoresStore.vue"),
      },
      {
        path: "mentores/:id/editar",
        name: "incubadora_Mentores_Edit",
        meta: { title: "editar mentores" },
        component: () =>
          import("../views/Incubadora/Mentores/MentoresEdit.vue"),
      },
      {
        path: "convidados",
        name: "incubadora_Convidados",
        meta: { title: "convidados da incubadora" },
        component: () => import("../views/Incubadora/Convidados.vue"),
      },
      {
        path: "convidados/convidados-tabela",
        name: "incubadora_Tabela de Convidados",
        meta: { title: "tabela de convidados" },
        component: () =>
          import("../views/Incubadora/Convidados/ConvidadosTable.vue"),
      },
      {
        path: "convidados/novo",
        name: "incubadora_Novo Convidado",
        meta: { title: "cadastrar convidado" },
        component: () =>
          import("../views/Incubadora/Convidados/ConvidadoStore.vue"),
      },
      {
        path: "convidados/:id/editar",
        name: "incubadora_Editar Convidado",
        meta: { title: "editar convidado" },
        component: () =>
          import("../views/Incubadora/Convidados/ConvidadoEdit.vue"),
      },
      {
        path: "convidados",
        name: "incubadora_Convidados",
        meta: { title: "convidados da incubadora" },
        component: () => import("../views/Incubadora/Convidados.vue"),
      },
      {
        path: "convidados/convidados-tabela",
        name: "incubadora_Tabela de Convidados",
        meta: { title: "tabela de convidados" },
        component: () =>
          import("../views/Incubadora/Convidados/ConvidadosTable.vue"),
      },
      {
        path: "convidados/novo",
        name: "incubadora_Novo Convidado",
        meta: { title: "cadastrar convidado" },
        component: () =>
          import("../views/Incubadora/Convidados/ConvidadoStore.vue"),
      },
      {
        path: "convidados/:id/editar",
        name: "incubadora_Editar Convidado",
        meta: { title: "editar convidado" },
        component: () =>
          import("../views/Incubadora/Convidados/ConvidadoEdit.vue"),
      },
      {
        path: "convidados/:id/visualizacao-espelhada",
        name: "incubadora_Convidados_Visu-espelhada",
        meta: { title: "Visualização espelhada da startup" },
        component: () =>
          import("../views/Incubadora/Convidados/ConvidadoMirror.vue"),
      },
      {
        path: "trilha",
        alias: "trilhas",
        name: "incubadora_Trilha",
        meta: { title: "trilha de qualificação" },
        component: () => import("../views/Incubadora/Trilha.vue"),
      },
      {
        path: "trilha/cursos/novo",
        name: "incubadora_curso_novo",
        meta: { title: "cadastrar curso" },
        component: () =>
          import("../views/Incubadora/Trilha/CourseRegister.vue"),
      },
      {
        path: "trilha/cursos/:id/editar",
        name: "incubadora_cursos_edit",
        meta: { title: "trilha de qualificação - editar curso" },
        component: () => import("../views/Incubadora/Trilha/CourseEdit.vue"),
      },
      {
        path: "trilha/cursos/:id/ranking",
        name: "incubadora_cursos_ranking",
        meta: { title: "trilha de qualificação - ranking de acesso" },
        component: () => import("../views/Incubadora/Trilha/Ranking.vue"),
      },
      {
        path: "trilha/cursos/:id/secoes",
        name: "incubadora_cursos_secoes",
        meta: {
          title: "trilha de qualificação - gerenciar seções do curso",
        },
        component: () => import("../views/Incubadora/Trilha/Sections.vue"),
      },
      {
        path: "trilha/cursos/:id/materiais/",
        name: "incubadora_cursos_materials",
        meta: { title: "trilha de qualificação - materiais do curso" },
        component: () => import("../views/Incubadora/Trilha/Materials.vue"),
      },
      {
        path: "comunidade",
        alias: "comunidades",
        name: "incubadora_Comunidade",
        meta: { title: "gestão de comunidade" },
        component: () => import("../views/Incubadora/Comunidade.vue"),
      },
      {
        path: "reunioes",
        alias: "reuniao",
        name: "incubadora_Reuniões",
        meta: { title: "registro de reuniões" },
        component: () => import("../views/Incubadora/Reunioes.vue"),
      },
      {
        path: "espacos",
        alias: "espaco",
        name: "incubadora_Espaços",
        meta: { title: "gestão de espaços físicos" },
        component: () => import("../views/Incubadora/Espacos.vue"),
      },
      {
        path: "equipe",
        name: "incubadora_Equipe",
        meta: { title: "equipe da incubadora" },
        component: () => import("../views/Incubadora/Membros.vue"),
      },
      {
        path: "equipe/equipe-tabela",
        name: "incubadora_Tabela de Membros",
        meta: { title: "tabela de membros" },
        component: () => import("../views/Incubadora/Members/MembrosTable.vue"),
      },
      {
        path: "equipe/novo",
        name: "incubadora_Novo Membro",
        meta: { title: "cadastrar membro" },
        component: () => import("../views/Incubadora/Members/MembroStore.vue"),
      },
      {
        path: "equipe/:id/editar",
        name: "incubadora_Editar Membro",
        meta: { title: "editar membro" },
        component: () => import("../views/Incubadora/Members/MembroEdit.vue"),
      },
      {
        path: "equipe/:id/visualizacao-espelhada",
        name: "incubadora_Equipe_Visu-espelhada",
        meta: { title: "Visulização espelhada de equipe" },
        component: () =>
          import("../views/Incubadora/Members/MembroMirror.vue"),
      },
      {
        path: "projetos",
        alias: "projeto",
        name: "incubadora_Projetos",
        meta: { title: "gerenciamento de projetos" },
        component: () => import("../views/Incubadora/Projetos.vue"),
      },
      {
        path: "mensagens",
        alias: "mensagem",
        name: "incubadora_Mensagens",
        meta: { title: "mensagens e notificações" },
        component: () => import("../views/Incubadora/Mensagens.vue"),
      },
      {
        path: "documentos",
        alias: "documento",
        name: "incubadora_Documentos",
        meta: { title: "gestão de documentos" },
        component: () => import("../views/Incubadora/Documentos.vue"),
      },
      {
        path: "configuracoes",
        alias: "configuracao",
        name: "incubadora_Configurações",
        meta: { title: "configurações" },
        component: () => import("../views/Incubadora/Configuracoes.vue"),
      },
      {
        path: "suporte",
        alias: "suporte",
        name: "incubadora_Suporte",
        meta: { title: "suporte" },
        component: () => import("../views/Incubadora/Suporte.vue"),
      },
      {
        path: "incubadoras/:id/visualizar",
        name: "test",
        meta: { title: "teste de visualização" },
        component: () => import("../views/Incubadora/Configuracoes.vue"),
      },
    ],
  },
  /*Startup routes*/
  {
    path: "/startup",
    redirect: "/startup/dashboard",
    beforeEnter(to, from, next) {
      if ((store.state.auth.roleMirror || store.state.auth.role)  === "startup") {
        next();
      } else {
        next({ name: "Login" });
      }
    },
    component: () => import("../layout/startup/DashboardLayout.vue"),
    children: [
      {
        path: "/startup",
        alias: "dashboard",
        name: "startup_Dashboard",
        meta: { title: "dashboard" },
        component: () => import("../views/Startup/Dashboard.vue"),
      },
      {
        path: "batches",
        name: "startup_batches",
        meta: { title: "Batches de startups" },
        component: () => import("../views/Startup/Batches.vue"),
      },
      {
        path: "batches/:id/acompanhamento",
        name: "startup_batches_acompanhamento",
        meta: { title: "batch de startups" },
        component: () => import("../views/Startup/Batches/BatchView.vue"),
      },
      {
        path: "batches/:id/startups",
        name: "startup_batches_startups",
        meta: { title: "startups selecionadas" },
        component: () => import("../views/Startup/Batches/BatchStartups.vue"),
      },
      {
        path: "batches/:idBatch/acompanhamento/tasks/:idTask/resposta",
        name: "startup_task_response",
        meta: { title: "batch de startups - ficha resposta" },
        component: () =>
          import("../views/Startup/Batches/Tasks/TaskResponseForm.vue"),
      },
      {
        path: "batches/:idBatch/acompanhamento/tasks/:idTask/visualizar",
        name: "startup_task_view",
        meta: { title: "batch de startups - ficha resposta" },
        component: () =>
          import("../views/Startup/Batches/Tasks/TaskResponseView.vue"),
      },
      {
        path: "recursos",
        name: "startup_recursos",
        meta: { title: "gestão de recursos" },
        component: () => import("../views/Startup/Recursos.vue"),
      },
      {
        path: "recursos/lançamentos/novo",
        name: "startup_lancamento_new",
        meta: { title: "novo lançamento" },
        component: () =>
          import("../views/Startup/Recursos/LancamentoForm.vue"),
      },
      {
        path: "recursos/lançamentos/:id/editar",
        name: "startup_lancamento_edit",
        meta: { title: "editar lançamento" },
        component: () =>
          import("../views/Startup/Recursos/LancamentoEdit.vue"),
      },
      {
        path: "trilha",
        name: "startup_Trilha",
        meta: { title: "trilha de qualificação" },
        component: () => import("../views/Startup/Trilha.vue"),
      },

      {
        path: "mentorias",
        alias: "mentoria",
        name: "startup_Mentorias",
        meta: { title: "mentorias" },
        component: () => import("../views/Startup/Mentorias.vue"),
      },
      {
        path: "mentorias/mentores",
        alias: "mentor",
        name: "startup_Mentores",
        meta: { title: "mentores" },
        component: () => import("../views/Startup/Mentorias/MentoresView.vue"),
      },
      {
        path: "reservas",
        alias: "reserva",
        name: "startup_Reservas",
        meta: { title: "reserva de espaço" },
        component: () => import("../views/Startup/Reservas.vue"),
      },
      {
        path: "comunidade",
        name: "startup_Comunidade",
        meta: { title: "comunidade" },
        component: () => import("../views/Startup/Comunidade.vue"),
      },
      {
        path: "equipe",
        name: "startup_Equipe",
        meta: { title: "equipe da startup" },
        component: () => import("../views/Startup/Membros.vue"),
      },
      {
        path: "equipe/equipe-tabela",
        name: "startup_Tabela de Membros",
        meta: { title: "tabela de membros" },
        component: () => import("../views/Startup/Members/MembrosTable.vue"),
      },
      {
        path: "equipe/novo",
        name: "startup_Novo Membro",
        meta: { title: "cadastrar membro" },
        component: () => import("../views/Startup/Members/MembroStore.vue"),
      },
      {
        path: "equipe/:id/editar",
        name: "startup_Editar Membro",
        meta: { title: "editar membro" },
        component: () => import("../views/Startup/Members/MembroEdit.vue"),
      },
      {
        path: "equipe/:id/visualizacao-espelhada",
        name: "admin_Equipe_Visu-espelhada",
        meta: { title: "Visulização espelhada de equipe" },
        component: () =>
          import("../views/Startup/Members/MembroMirror.vue"),
      },
      {
        path: "projetos",
        alias: "projeto",
        name: "startup_Projetos",
        meta: { title: "gerenciamento de projetos" },
        component: () => import("../views/Startup/Projetos.vue"),
      },
      {
        path: "documentos",
        alias: "documento",
        name: "startup_Documentos",
        meta: { title: "painel de documentos" },
        component: () => import("../views/Startup/Documentos.vue"),
      },
      {
        path: "suporte",
        alias: "suporte",
        name: "startup_Suporte",
        meta: { title: "suporte" },
        component: () => import("../views/Startup/Suporte.vue"),
      },
      {
        path: "configuracoes",
        alias: "configuracao",
        name: "startup_Configurações",
        meta: { title: "Configurações" },
        component: () => import("../views/Startup/Configuracoes.vue"),
      },
      {
        path: "startups/:id/visualizar",
        name: "teste",
        meta: { title: "teste de visualização" },
        component: () => import("../views/Startup/Configuracoes.vue"),
      },
    ],
  },
  /*Mentor routes*/
  {
    path: "/mentor",
    component: () => import("../layout/mentor/DashboardLayout.vue"),
    children: [
      {
        path: "/mentor",
        alias: "dashboard",
        name: "mentor_Dashboard",
        meta: { title: "dashboard" },
        component: () => import("../views/Mentor/Dashboard.vue"),
      },
      {
        path: "batches",
        alias: "batch",
        name: "mentor_Batches",
        meta: { title: "batches de startups" },
        component: () => import("../views/Mentor/Batches.vue"),
      },
      {
        path: "avaliacoes",
        alias: "avaliacao",
        name: "mentor_Avaliações",
        meta: { title: "avaliação de startups em aberto" },
        component: () => import("../views/Mentor/Avaliacoes.vue"),
      },
      {
        path: "pitch-days",
        alias: "pitch-day",
        name: "mentor_Pitch Days",
        meta: { title: "pitch days" },
        component: () => import("../views/Mentor/PitchDays.vue"),
      },
      {
        path: "due-diligences",
        alias: "due-diligence",
        name: "mentor_Due Diligences",
        meta: { title: "due diligences" },
        component: () => import("../views/Mentor/DueDiligences.vue"),
      },
      {
        path: "mentorias",
        alias: "mentoria",
        name: "mentor_Mentorias",
        meta: { title: "mentorias para as startups" },
        component: () => import("../views/Mentor/Mentorias.vue"),
      },
      {
        path: "comunidade",
        name: "mentor_Comunidade",
        meta: { title: "comunidade" },
        component: () => import("../views/Mentor/Comunidade.vue"),
      },
      {
        path: "documentos",
        alias: "documento",
        name: "mentor_Documentos",
        meta: { title: "painel de documentos" },
        component: () => import("../views/Mentor/Documentos.vue"),
      },
      {
        path: "configuracoes",
        alias: "configuracao",
        name: "mentor_Configurações",
        meta: { title: "configurações" },
        component: () => import("../views/Mentor/Configuracoes.vue"),
      },
    ],
  },
  /*Member routes*/
  {
    path: "/membro",
    redirect: "/membro/dashboard",
    beforeEnter(to, from, next) {
      if ((store.state.auth.roleMirror || store.state.auth.role)  === "member") {
        next();
      } else {
        next("/login");
      }
    },
    component: () => import("../layout/membro/DashboardLayout.vue"),
    children: [
      {
        path: "/membro",
        alias: "Dashboard",
        name: "member_Dashboard",
        meta: { title: "dashboard" },
        component: () => import("../views/Membro/Dashboard.vue"),
      },
      {
        path: "trilha",
        name: "member_Trilha",
        meta: { title: "trilha de qualificação" },
        component: () => import("../views/Membro/Trilha.vue"),
      },
      {
        path: "trilha/cursos/:id/visualizar",
        name: "member_Curso",
        meta: { title: "trilha de qualificação - curso" },
        component: () => import("../views/Membro/Trilha/Curso.vue"),
      },
      {
        path: "suporte",
        alias: "suporte",
        name: "member_Suporte",
        meta: { title: "suporte" },
        component: () => import("../views/Membro/Suporte.vue"),
      },
      {
        path: "configuracoes",
        alias: "configuracao",
        name: "member_configurações",
        meta: { title: "configurações" },
        component: () => import("../views/Membro/Configuracoes.vue"),
      },
    ],
  },
  /*Guest routes*/
  {
    path: "/convidado",
    redirect: "/convidado/dashboard",
    beforeEnter(to, from, next) {
      if ((store.state.auth.roleMirror || store.state.auth.role) === "guest") {
        next();
      } else {
        next("/login");
      }
    },
    component: () => import("../layout/convidado/DashboardLayout.vue"),
    children: [
      {
        path: "/convidado",
        alias: "Dashboard",
        name: "guest_Dashboard",
        meta: { title: "dashboard" },
        component: () => import("../views/Convidado/Dashboard.vue"),
      },
      {
        path: "trilha",
        name: "guest_Trilha",
        meta: { title: "trilha de qualificação" },
        component: () => import("../views/Convidado/Trilha.vue"),
      },
      {
        path: "trilha/cursos/:id/visualizar",
        name: "guest_Curso",
        meta: { title: "trilha de qualificação - curso" },
        component: () => import("../views/Convidado/Trilha/Curso.vue"),
      },
      {
        path: "suporte",
        alias: "suporte",
        name: "guest_Suporte",
        meta: { title: "suporte" },
        component: () => import("../views/Convidado/Suporte.vue"),
      },
      {
        path: "configuracoes",
        alias: "configuracao",
        name: "guest_configurações",
        meta: { title: "configurações" },
        component: () => import("../views/Convidado/Configuracoes.vue"),
      },
    ],
  },
  {
    path: "/tables",
    name: "Tables",
    component: Tables,
  },
  {
    path: "/billing",
    name: "Billing",
    component: Billing,
  },
  {
    path: "/virtual-reality",
    name: "Virtual Reality",
    component: VirtualReality,
  },
  {
    path: "/rtl-page",
    name: "RTL",
    component: RTL,
  },
  // Profile, bom modelo de edição de usuário
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
  },
  { path: "/:catchAll(.*)", redirect: "/login" },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

router.beforeEach((to, from, next) => {
  const toBaseRoute = ["/", "/login"].includes(to.fullPath);
  if (toBaseRoute) {
    const Roles = new Map();
    Roles.set("admin", "admin");
    Roles.set("rectory", "reitoria");
    Roles.set("incubator", "incubadora");
    Roles.set("startup", "startup");
    Roles.set("member", "membro");
    Roles.set("guest", "convidado");
    if (store.state.auth.roleMirror || store.state.auth.role ) {
      next(`/${Roles.get(store.state.auth.roleMirror || store.state.auth.role)}`);
    } else next();
  } else {
    next();
  }
});

export default router;
